import React, { useRef } from 'react';
import Header from '../components/Header'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Typography, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, TextField, InputAdornment, Divider, LinearProgress, Tooltip, Dialog, DialogTitle, DialogContent, Snackbar, Alert, CircularProgress , Menu, Drawer, AppBar, Toolbar, Slide, Radio } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CloseIcon from '@mui/icons-material/Close';
import env from 'react-dotenv';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Switch, { switchClasses } from '@mui/joy/Switch';
import { Theme } from '@mui/joy';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import imageLogo from "../images/otpImage.png";
import eezibLogo from "../images/eezib.png";
import { HandleApi } from '../HandleApi';
import Footer from './Footer';
import Slider from '@mui/material/Slider';
import { useTheme, useMediaQuery } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';
import downloadfile from "../images/downloadExcel.jpg";
import exceLogo from "../images/excelPng.png";
import pdfLogo from "../images/pdfIcon.png";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import eezibWhite from "../images/eezib2.png";
import pgImage from "../images/pgIMage.png";
import eeLogo from "../images/ee.png";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import cardIcon from "../images/pgIcon.png";
import orderImage from "../images/orderCompleted.jpg";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';
import ecomImg from "../images/ecommerce.jpg";
import posImg from "../images/POS.jpg";
import atmImg from "../images/ATM.jpg";
import contactLess from "../images/contactless.jpg";
import topupImg from "../images/topup.jpg";
// import "jspdf-autotable";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import confetti from 'canvas-confetti';
import userQr from "../images/qrCode.png"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const ActivatedCard = () => {
    
    const [age, setAge] = React.useState('');
    const [age2, setAge2] = React.useState('');
    const [age3, setAge3] = React.useState('');
    const [category , setCategory] = React.useState();  
    const [progress1, setProgress1] = React.useState(false);
    const [progress3, setProgress3] = React.useState(false);
    const [categoryId , setCategoryId] = React.useState('');
    const [subCat , setSubCat] = React.useState('')
    const [handleSubCategory , setHandleSubCategory] = React.useState('');
    const [tableData , setTableData] = React.useState();
    const [dialogCardDetail , setDialogCardDetail] = React.useState(false);
    const [lockStatus, setLockStatus] = React.useState('');
    const [cardLockReason , setCardLockReason] = React.useState('');
    const [entityId , setEntityId] = React.useState('');
    const [snackMessage , setSnackMessage ] = React.useState('');
    const [snackRightOpen, setSnackRightOpen ] = React.useState(false);
    const [ dialogProgress, setDialogProgress ]  = React.useState(false)
    const [ cardLockProgress, setCardLockProgress ] = React.useState(false);
    const [fetchUserBalance , setFetchUserBalance] = React.useState("");
    const [snackEError , setSnackEError] = React.useState('');
    const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
    const [page , setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openLimit , setOpenLimit ] = React.useState(false);
    const [ inputLimitValue ,setInputLimitValue ] = React.useState('')
    const [ inputLimitValueID , setInputLimitValueID ] = React.useState('');
    const [ enableTrans, setEnableTrans ] = React.useState(false);
    const [ enabletransID   ,  setEnableTransID ] = React.useState('');
    const [ enabletransValue , setEnableTransValue ] = React.useState("");
    const [lockApi , setLockApi] = React.useState(''); 
    const [limitProgress , setLimitProgress] = React.useState(false);
    const [ transProgress , setTransProgress ] = React.useState(false);
    const  [transTableData , setTransTableData ] = React.useState();
    const [openTransTable , setOpenTransTable] = React.useState(false);
    const [ switchValue , setSwithValue ] =React.useState(false);
    const [ transPage , setTransPage ] = React.useState(1);
    const [ transRowPerPage , setTransRowPerPage ] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ catClick , setCatClick ] = React.useState("");
    const [checked, setChecked] = React.useState(0);
    const [ finalTable , setFinalTable ] = React.useState(false);
    const [ openConcent , setOpenConcent] = React.useState(false);
    const [ concentData , setConcentData ] = React.useState();
    const  [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const [ progress , setProgress ] = React.useState(false);
  const [ currentData , setCurrentData ] = React.useState();
  const [ verifyDataDialog , setVerifyDataDialog ] = React.useState(false);
  const [otpValue , setOtpValue] = React.useState('');
  const  [openKYC , setOPenKYC ] = React.useState(false)
  const [ kycId , setKycId ] = React.useState('');
  const [verifyUser , setVerifyUser] = React.useState("");
  const [ verifyEmail , setVerifyEmail ] = React.useState('');
  const [ verifyDob , setverifyDob ] = React.useState('');
  const [ verifyAddress , setVerifyAddress ] = React.useState('');
  const [ verifyAddress2 , setVerifyAddress2 ] = React.useState("");
  const [ verifyAddress3 , setVerifyAddress3 ] = React.useState("");
  const [ city , setCity ] = React.useState("");
  const [ state , setState ] = React.useState('');
  const [ pincode, setPinCode ] = React.useState("");
  const [ downloadTnx , setDownloadTnx ] = React.useState(false); 
  const [ startDate , setStartDate ] = React.useState("");
  const [ endDate , setEndDate ] = React.useState("");
  const [ reportData , setReportData ] = React.useState();
  const [ cardTransId , setCardTransId ] = React.useState("");
  const [ progress2 , setProgress2 ] = React.useState(false);
  const [ proceedCoin , setProceedCoin ] = React.useState(0);
  const [ lowBalanceData , setLowbalanceData ] = React.useState();
  const  [ handleSuccess , setHandleSuccess ] = React.useState(false);
  const [ jsonSuccess, setJsonSuccess ] = React.useState("");
  const  [ lowbalance , setLowBalance ] = React.useState(false);
  const [ successDialogue , setSuccessDialogue ] = React.useState(false);
  const [ openQr, setOpenQr ] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false); 
  const [open, setOpen] = React.useState(false);
  const [ handleError, setHandleError ] = React.useState(false);
  const [ jsonError , setJsonError ] = React.useState("");
  const [denomination, setSelectedMenu] = React.useState("");
  const [ pgUrl , setPgUrl ] = React.useState('');
  const [ openUrlDialogue , setOpenUrlDialogue ] = React.useState(false);
  const [ qrCode , setQrCode ] = React.useState('');
  const [ qrName , setQrName ] = React.useState("");
  const [ qrWallet , setQrWallet ] = React.useState("");
  const [ maxAmount , setMaxAmount ] = React.useState("");

  const [ txnProg , setTxnProg ] = React.useState(false); 

  const  [ aadharNo , setAadharNo ] = React.useState('');
  const [ panNo , setPanNo ] = React.useState('');
  const [ fatherName , setFatherName ] = React.useState('');
  const  [ imgfile , setImgFile ] = React.useState('');
  const   [ kycAddress , setKycAddress ] = React.useState('');

  const [ otpEntityId , setOtpEntityId ] = React.useState('');

  const [ otpVerify , setotpVerify ] = React.useState(false);

  const [selectedFile, setSelectedFile] = React.useState(null);

  const [base64Image, setBase64Image] = React.useState('');

  const [imgfileType , setImgFileTYpe] = React.useState('');
  
  const  [ limitApi , setLimitApi] = React.useState('');

  const [decideLimit , setDecideLimit ] = React.useState(''); 

  const [ finoLimit ,setFinoLimit ] = React.useState("");

  const [ verifyProg , setVerifyProg ] = React.useState(false);

  const [ otpProg , setOtpProg ] = React.useState(false);

  const [ convertPhy , setConvertPhy ] = React.useState(false);

  const [ anchorEmenu, setAnchorEMenu ] = React.useState(null);
  const [ currentMenuData , setCurrentmenuData ] = React.useState();
  const [ currentMenuId , setCurrentmenuId ] = React.useState("");

  const [ cardLimitData , setCardLimitData ] = React.useState();
  const [ ecommLimit , setEcomLimit ] = React.useState("");
  const [ Pos , setPos ] = React.useState("");
  const [Atm , setAtm ] = React.useState(""); 
  const [ contactless , setContactless ] = React.useState("");
  const [ limitCardProgress , setLimitCardProgress ] = React.useState(false);
  const [ cardsLimitId , setCardsLimitId ] = React.useState("");
  const [ EcomCheck , setEomCheck] = React.useState(0);
  const [ PosCheck , setPosCheck ] = React.useState(0)
  const [ atmCheck , setAtmCheck ] = React.useState(0);
  const [ contactlessCheck , setContactlessCheck ] = React.useState(0);
  const [enableTxnData , setEnableTxnData ] = React.useState(); 
  const [ openTopup, setOpenTopup ] = React.useState(false);

  const [ topupRef , setTopupref ] = React.useState("");
  const [ topupAmount , setTopupAmount ]  = React.useState("");
  const [ topupCard , setTopupCard ] = React.useState("");
  const [ topupMobile , setTopupMobile ] = React.useState(""); 

  const  [ selectedValue , setSelectedValue ] = React.useState("");
  const [seconds, setSeconds] = React.useState(90); 
  const [isActiveSwitch , setisActiveSwitch ] = React.useState(false);

  const [ limitApiEnd , setLimitApiEnd ] = React.useState("");
  const [ statusApiEnd , setStatusApiEnd ] = React.useState("");

  const [ limitData , setLimitData ] = React.useState({
    atm:"",
    contactless:"",
    ecommerce:"",
    pointOfSale:"",
  })

  React.useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      setIsActive(true);
      setOpenQr(true);
        interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
    } else if (seconds === 0) {
        // Stop everything when the timer reaches zero
        setIsActive(false);
        setOpenQr(false);
        setLowBalance(false);
    }

    // Cleanup the interval when the component unmounts or when the timer ends
    return () => clearInterval(interval);
}, [isActive, seconds]);


// Optional: Ensure API is not called if the timer ends during an API call
React.useEffect(() => {
    if (seconds === 0) {
        // Cleanup any remaining intervals for the API call
        setIsActive(false);
        setOpenQr(false);
    }
}, [seconds]);



  const handleSDialogue = () => {

    const end = Date.now() + 2 * 1000; // 15 seconds
    const colors = ["#57bcff", "#ffffff"];
  
    // Define the continuous confetti animation
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
        zIndex: 9999,
      });
  
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
        zIndex: 9999,
      });
  
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();

    setTimeout(() => {
        setSuccessDialogue(true);
    },0)
    }


  const handleLowBalance = () => {
    setLowBalance(false);
  }

  const handleTopup = () => {
    setOpenTopup(false);
  }

  const location = useLocation();
  


  const svgData = location.state?.data;



  const handleDTxnClose = () => {
    setDownloadTnx(false)
  }

  const handleEcomCheck = (e) => {
    const bool = EcomCheck === 0 ? 1 : 0;
    setEomCheck(bool);
}

const handlePosCheck = (e) => {
  const bool = PosCheck === 0 ? 1 : 0;
  setPosCheck(bool);
}

const handleAtmCheck = (e) => {
  const bool = atmCheck === 0 ? 1 : 0;
  setAtmCheck(bool);
}

const handleContLessCheck = (e) => {
  const bool = contactlessCheck === 0 ? 1 : 0;
  setContactlessCheck(bool);
}


  const [value, setValue] = React.useState(cardLimitData?.Ecommerce?.limit || 101 );

  React.useEffect(() => {
    setValue(cardLimitData?.Ecommerce?.limit || 101);
  }, [cardLimitData]);

 
  const handleSliderChange = (event, newValue) => {
    setEcomLimit(newValue);

  };

  const handleSliderChangePos = (event, newValue) => {
    setPos(newValue);

  };

  const handleSliderChangeAtm = (event, newValue) => {
    setAtm(newValue);

  };

  const handleSliderChangeContact = (event, newValue) => {
    setContactless(newValue);

  };

  // const handleTextFieldChange = (event) => {
  //   const newValue = event.target.value;

  //   if (newValue === '' || !isNaN(Number(newValue))) {
  //     setValue(newValue === '' ? '' : Number(newValue));
  //     setCardLimitData(prevState => ({
  //       ...prevState,
  //       Ecommerce: {
  //         ...prevState.Ecommerce,
  //         limit: newValue === '' ? '' : Number(newValue)
  //       }
  //     }));
  //   }
  // };

  //   const handleTextFieldChange = (event) => {
  //   const newValue = event.target.value;

  //   if (newValue === '' || !isNaN(Number(newValue))) {
  //     setValue(newValue === '' ? '' : Number(newValue));
  //     setCardLimitData(prevState => ({
  //       ...prevState,
  //       Ecommerce: {
  //         ...prevState.Ecommerce,
  //         limit: newValue === '' ? '' : Number(newValue)
  //       }
  //     }));
  //   }
  // };


  const [anchorElVoucher, setAnchorElVoucher] = React.useState(null);

    const openVoucher = Boolean(anchorElVoucher);

    const handleVoucherClick = (event) => {
      setAnchorElVoucher(event.currentTarget);
    };
    const handleVoucherClose = () => {
      setAnchorEl(null);
    };


  const openMenu = Boolean(anchorEmenu);

  const handleMenuClick = ( event ,actions, id) => {
    setAnchorEMenu(event.currentTarget);
    setCurrentmenuData(actions);
    setCurrentmenuId(id)
  }

  const handleMenuplation = (data) => {
     handleMenuClick(data);
  }

  const handleMenuClose = () => {
    setAnchorEMenu(null);
  }



  const [ add1 , setAdd1 ] = React.useState('');
  const  [ add2 , setAdd2 ] = React.useState('');
  const [ add3 , setAdd3 ] = React.useState('');
  const [ phyCity , setPhyCity ] = React.useState('');
  const [ phyState , setPhyState ] = React.useState('');
  const [ phyPin , setPhyPin ] = React.useState('');
  const [ phyApi , setPhyApi ] = React.useState('');
  const [ phyId , setPhyId ] = React.useState('');
  const [ phyProgress , setPhyProgress ] = React.useState(false);



  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));



  const fileInputRef = useRef(null);

  const authAccess = Cookies.get("demoCAuth");

  const handleVerification = async() => {

    setOpenBackdrop(true);

    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/storeform`;
    const method = "POST";
    const bodyData = { "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress  }

    // const fetchData = await fetch(`${url}/api/storeform`, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` })
    // });


    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData, "authAccess":authAccess });


    // console.log({ "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` });
    // const json = await fetchData.json();
    setOpenBackdrop(false);

    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
        
        window.open(json.message,"");

      setAadharNo("");
      setPanNo("");
      setFatherName("");
      setKycAddress("");
      setBase64Image("");
      setImgFileTYpe("");
        if(fileInputRef.current){
          fileInputRef.current.value="";
        }
        setOPenKYC(false);
      
      }

      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);

        setTimeout(() => {
          setAadharNo("");
      setPanNo("");
      setFatherName("");
      setKycAddress("");
      setBase64Image("");
      setImgFileTYpe("");

      // fileInputRef.current.value="";
        setOPenKYC(false);
        },600)
      }
    }

  }

  const handleImageChange = (event) => {

    setBase64Image('');

    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);

      if (file.size > 600 * 1024) { // 200 KB in bytes
        setSnackRightEOpen(true);
        setSnackEError('Image Should Not Be Greater Than 600 KB.');
        fileInputRef.current.value="";
        return;
      }

      const fileType = file.name.split(".")[1];
      setImgFileTYpe(fileType);
      const reader = new FileReader();
      reader.onloadend = () => {
        // reader.result contains the base64-encoded image data
        const baseData = reader.result.split(",")[1]
        setBase64Image(baseData);
      };
      reader.readAsDataURL(file)

    } else {
      setSelectedFile(null);
      window.alert('Please select a valid image file.');
      return
    }
  };


  const otpKycClose = () => {
    setotpVerify(false);
  }

  const fetchKycStatus = async(e, id) => {

    try{

    console.log(e, id)
    // const fetchData = await fetch(e, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Accept":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":id })
    // });

    const url = process.env.REACT_APP_UAPI_URL;
    const method = "POST";
    // const bodyData = { "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` }


    const json = await HandleApi({"apiUrl":e, "method":method, "bodyData":{"id":id}, "authAccess":authAccess });


    setOpenBackdrop(false);

    if(json){

      if(json.status === "success" && json.statuscode === 200 ){
        setSnackRightOpen(true);
        setSnackMessage(json.message);
        
        if(json?.url){
          setTimeout(() => {
            window.open(json.url,'');
          },1000)
        }

      }

      if(json.status === "error"){
        setSnackRightEOpen(true)
          setSnackEError(json.message)
      }

    }
    }catch(err){
        console.log(err)
        setSnackRightEOpen(true)
        setSnackEError(err);
    }

    

  }

  const kycFormData = async(e) => {

    try{

      setOpenBackdrop(true);

    // const fetchData = await fetch(`${url}/api/kycform`,{
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":e })
    // });
    // const json = await fetchData.json();

    console.log("kyc id : ",e)

    const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/kycform`;
    const method = "POST";


    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":{"id":e}, "authAccess":authAccess });

    setOpenBackdrop(false);


    if(json){
      if(json.status === "success" && json.statuscode === 200 ){

        if(json.message.show_form === 1){
            setKycId(e);
            setAadharNo(json.message.data.Aadhaar);
            setPanNo(json.message.data.PAN);
            setFatherName(json.message.data.FATHER_NAME);
            setKycAddress(json.message.data.address);
            setOPenKYC(true);
        }

        if(json.message.show_form === 0 ){
          const apiUrl = `${process.env.REACT_APP_UAPI_URL}${json.message.url}`;
          const cardId = json.message?.data.id;

          fetchKycStatus(apiUrl, cardId);

        }

        

        }

      if(json.status === "error"){
        setSnackRightEOpen(true);
      setSnackEError(json.message);
      }
    }

    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err);
    }   
  }


  const handleOpenQr = async() => {
    setOpenQr(false);
    setTimeout(() => {
        window.location.reload();
    },200)
}


  const handleOtpDialog = () => {
    setOpenConcent(false);
    setVerifyDataDialog(true);
    setCheck(false);
  }

  const verifyOtpClose = () => {

  }

    // const handleCheck = (e) => {
    //   setCheck(e.target.checked);
    // }

    const verifyDataClose = () => {
      setVerifyDataDialog(false);
    }



  const handleBackdropClose = () => {
    setOpenBackdrop(false)
  }
  
    const handleCheckedSwitch = (e) => {
      
        const bool = checked === 0 ? 1 : 0;
        setChecked(bool);
        Cookies.remove("tcValue");
        Cookies.set("tcValue","eeib");
    }

    const handleConcentClose = () => {
      setOpenConcent(false);
      setCheck(false);
    }

      const openCatMenu = Boolean(anchorEl);

      const handleCloseCatMenu = (event) => {
        setAnchorEl(null);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

    const navigate = useNavigate();
    const name = Cookies.get("userName");


   
    // const handleUserTable = () => {
    //   if(catClick === "Eezib Transcorp"){
    //     Cookies.remove("tcValue");
    //     Cookies.set("tcValue","transcorp")
    //       return tableData?.filter((data) => 
    //         data.category_name === "Eezib Fino Payments Bank"
    //       )
    //   }else if( catClick === "Eezib Fino Payments Bank" ){
    //     Cookies.remove("tcValue");
    //     Cookies.set("tcValue","fino")
    //     return tableData?.filter((data) => 
    //     data.category_name === ""
    //   )      
    // }
    //   else{
    //     Cookies.set("tcValue","eezib")
    //     return tableData
    //   }
    // }



    const handleUserTable = () => {
      if(catClick === "Eezib Transcorp"){
        Cookies.remove("tcValue");
        Cookies.set("tcValue","transcorp")
          return tableData?.filter((data) => 
            data.cat_id === 4
          )
      }else if( catClick === "Eezib Fino Payments Bank" ){
        Cookies.remove("tcValue");
        Cookies.set("tcValue","fino")
        return tableData?.filter((data) => 
        data.cat_id === 3
      )      
    }
      else{
        Cookies.set("tcValue","eezib")
        return tableData
      }
    }
 
    const result = handleUserTable();



    const startIndex = (page -1)* rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const slicedData = handleUserTable()?.slice(startIndex, endIndex); 

        const transStartIndex = (transPage - 1)* transRowPerPage;
        const transEndIndex = transStartIndex + transRowPerPage;
        const transSlicedData = transTableData?.slice(transStartIndex , transEndIndex);

        const handleTransTableClose = () => {
          setOpenTransTable(false);
        }

        const handleTransClose = () => {
          setEnableTrans(false);
        }

        const handleEnableTransChange = (e) => {
          setEnableTransValue(e.target.value)
        }

    const handleChange = (event) => {
      console.log( "decide gateway: ", event.target.value);
      const value = event.target.value;
      setSelectedValue(value);
      };

      const handleLimitChange = (e) => {
        setFinoLimit(e.target.value)
      }

      const handleLimitClose = () => {
        setOpenLimit(false)
      }
  
      const handleChange2 = (event) => {
        setSubCat(event.target.value);
        };

        const handleLockClose = () => {
          setDialogCardDetail(false);
        }

        const handleLockChange = (event) => {
          setLockStatus(event.target.value)
        }

        const handleRightSnackClose = () => {
            setSnackRightOpen(false)
        }

        const handleRightSnackEClose = () => {
          setSnackRightEOpen(false);
        }



  const getCategory = async() => {

    const url = process.env.REACT_APP_UAPI_URL

    try{

      setProgress1(true)
    
        const data = await fetch(`${url}/api/category`,{
          headers:{
            "Accept":"application/json",
            "Authorization":`Bearer ${authAccess}`,
          },
        });
        const json = await data.json();
    
        if(json){
          if(json.status === "success" && json.statuscode === 200 ){
            setCategory(json.message);
            setProgress1(false)
          } else if( json.message === "Unauthenticated." ){
            setSnackRightEOpen(true);
            setSnackEError("Session Expire, Login Again..")
            setTimeout(() => {
              Cookies.remove("demoCAuth")
              navigate("/", { replace:true });
          },3000)
          }
        }

    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err)
    }
  }
  // const subCategory = async() => {
  //   setProgress2(true)


  //   const data = await fetch(`http://siddhi.local/b2c_live/api/subcategory/${age2}`, {
  //     headers:{
  //       "Accept":"application/json",
  //       "Authorization":`Bearer ${authAccess}`,
  //     },
  //   });
  //   const json = await data.json();
  //   if(json){
  //     if(json.Status === "Success"){
  //       setSubCategoryCard(json.Data)
  //       setProgress2(false)
  //     }
  //   }
  // }

  const handleLockUnlock = (e) => {
    setEntityId(e)
    setDialogCardDetail(true)
  }
  
  // React.useEffect(() => {
  //   getCategory();
  // },[])



  const handleButtonClick = async() => {
    const url = process.env.REACT_APP_UAPI_URL
    try{      
      setDialogProgress(true)
      const fetchData = await fetch(`${url}/api/activatedCards`, {
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({"cat_id":age2})
      });
      const json = await fetchData.json();
      
      if(json.status === "success" && json.statuscode === 200 ){
        if(json.message){
          const jsonTableData = json.message;
          setTableData(jsonTableData);
          setDialogProgress(false);
        }  
      }
      if(json.status === "error"){
        if(json.message){
          setSnackRightEOpen(true);
          setSnackEError(json.message)
        }
      }
      setDialogProgress(false)
    }catch(err){
      setDialogProgress(false);
    }

  }   


  async function handleCardLockUnlock(f,api){

    setCardLockProgress(true)
    setOpenBackdrop(true);

    try{
      // const dataFetch = await fetch( `${process.process.env.REACT_APP_UAPI_URL}${api}` , {
      //   method:"POST",
      //   headers:{
      //     "Content-Type":"application/json",
      //     "Authorization":`Bearer ${authAccess}`
      //   },
      //   body:JSON.stringify({ "id":f , "flag":lockStatus })
      // })
      // const json = await dataFetch.json();

      const apiUrl = `${process.env.REACT_APP_UAPI_URL}${api}`;
      const method = "POST";
      const bodyData = { "id":f }


      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });


      setCardLockProgress(false)
      setOpenBackdrop(false);
      if(json){
        if(json.status === 'success' && json.statuscode === 200 ){
            setCardLockProgress(false)
            setSnackRightOpen(true)
            setSnackMessage(json.message);
            cardData();
        }
        else if(json.status === "error"){ 
          setCardLockProgress(false)
          setSnackRightEOpen(true);
          setSnackEError(json.message);
        }
      }
    }catch(err){
      setSnackRightEOpen(true);
      setSnackEError(err)
    }


  }

  const fetchBalance = async(e, api) => {
    setOpenBackdrop(true);
    try{

      // const fetchApi = await fetch(`${api}`,{
      //   method:"POST",
      //   headers:{
      //     "Content-Type":"application/json",
      //     "Authorization":`Bearer ${authAccess}`
      //   },
      //   body:JSON.stringify({"id":e})
      // })
      // const json = await fetchApi.json();
      //   console.log("balance data : ",json);

      const method = "POST";
      const bodyData = {"id":e}

      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });


        setOpenBackdrop(false);
      if(json){
        if(json.status === "success" && json.statuscode === 200 ){
          if(json.message){
            setSnackRightOpen(true)
            setSnackMessage(json.message);
          }
        }
        else if(json.status === "error"){
          setSnackEError("")
          setSnackRightEOpen(true);
          setSnackEError(json.message)
        }
      }
      
    }catch(err){
      setSnackRightEOpen(true);
      setSnackEError(err);
    }

  }

  const getCardDetails = async(e, api, redirect) => {
    setOpenBackdrop(true);

try{
  

  const url = process.env.REACT_APP_UAPI_URL;
  const method = "POST";
  const bodyData = {"id":e}


const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchData = await fetch( `${api}` , {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({ "id":e })
  // })
  // console.log({ "id":e })
  // const json =  await fetchData.json();
   setOpenBackdrop(false);

  if(json){
    if(json.status === "success" && json.statuscode === 200 ){
      window.open(json.message, '');
    }
    else if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message)
    }}
}catch(err){
  setSnackRightEOpen(true);
  setSnackEError(err);
}
    
    }

      
    const fetchTransitions = async( e , api, redirect ) => {
      setOpenBackdrop(true);
      try{

        const url = process.env.REACT_APP_UAPI_URL;
        const method = "POST";
        const bodyData = {"id":e}

       // const fetchData = await fetch( `${api}` ,{
        // const fetchData = await fetch( `${api}` ,{
        //     method:"POST",
        //     headers:{
        //       "Content-Type":"application/json",
        //       "Authorization":`Bearer ${authAccess}`
        //     },
        //     body:JSON.stringify({ "id":e })
        // });
        // const json  = await fetchData.json();

      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });


        setOpenBackdrop(false);
        if(json){
          if(json.status === "error"){
            setSnackRightEOpen(true);
            setSnackEError(json.message);
          }
          if(json.status === "success" && json.statuscode === 200 ){
            if(redirect === "NO"){
              setTransTableData(json.message);
              setOpenTransTable(true);
             // navigate("/viewCard", {state : {data : json.Message}})
            }
            else if(redirect === "Yes"){
              window.open(json.message)
            }
          } 
        }
      }catch(err){
        setSnackRightEOpen(true);
        setSnackEError(err);
      }

      }

      const setcardLimit = async() => {
        setLimitProgress(true);
        const url = process.env.REACT_APP_UAPI_URL
          try{
            // const fetchData = await fetch( limitApi ,{
            //   method:"POST",
            //   headers:{
            //     "Content-Type":"application/json",
            //     "Authorization":`Bearer ${authAccess}` 
            //   },
            //   body:JSON.stringify( decideLimit === "transcorp" ?  { "id":inputLimitValueID, "limit":inputLimitValue } : decideLimit === "fino" ? { "id":inputLimitValueID, "limit":inputLimitValue, "period":finoLimit } : null )
            // });
            
            // const json = await fetchData.json();

            const url = process.env.REACT_APP_UAPI_URL;
            const method = "POST";
            const bodyData = decideLimit === "transcorp" ?  { "id":inputLimitValueID, "limit":inputLimitValue } : decideLimit === "fino" ? { "id":inputLimitValueID, "limit":inputLimitValue, "period":finoLimit } : null;

            const json = await HandleApi({"apiUrl":limitApi, "method":method, "bodyData":bodyData , "authAccess":authAccess });


            setLimitProgress(false)
    
            if(json){
    
              if(json.status === "success" && json.statuscode === 200 ){
                setSnackRightOpen(true)
                setSnackMessage(json.message);

                setTimeout(() => {
                  setInputLimitValue("")
                },1000)
              }
    
              if(json.state === "error")
              setSnackRightEOpen(true);
                setSnackEError(json.message);

                setTimeout(() => {
                  setInputLimitValue("")
                },1000)
            }

          }catch(err){
            setSnackRightEOpen(true);
            setSnackEError(err);
          }

      }

      const enableTxn = async(f, api) => {
        setTransProgress(true);
        try{
          // const fetchData = await fetch(`${url}${api}`, {
            //   method:"POST",
            //   headers:{
              //     "Content-Type":"application/json",
          //     "Authorization":`Bearer ${authAccess}`
          //   },
          //   body:JSON.stringify({ "id":f })
          // })
          // const json = await fetchData.json();

          const url = process.env.REACT_APP_UAPI_URL;
            const method = "POST";
            const bodyData = { "id":f }

            const json = await HandleApi({"apiUrl":`${url}${api}`, "method":method, "bodyData":bodyData , "authAccess":authAccess });


          setTransProgress(false)
          if(json){
            if(json.status === "success" && json.statuscode === 200){
              setSnackRightOpen(true)
              setSnackMessage(json.message);
              cardData();
            }
            if(json.status === "error"){
              setSnackRightEOpen(true);
              setSnackEError(json.message)
            }
          }
        }catch(err){
          setSnackRightEOpen(true);
            setSnackEError(err);
        }


      }


      const setCardPin = async(e, api) => {

        try{

          setOpenBackdrop(true);
          // const fetchData = await fetch(`${api}`,{
          //   method:"POST",
          //   headers:{
          //     "Content-Type":"application/json",
          //     "Authorization":`Bearer ${authAccess}`
          //   },
          //   body:JSON.stringify({ "id":e })
          // } );
  
          // const json = await fetchData.json();

          const url = process.env.REACT_APP_UAPI_URL;
          const method = "POST";
          const bodyData = { "id":e }

          const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

          setOpenBackdrop(false);
          
          if(json){
            if(json.status === "success" && json.statuscode === 200 ){
              window.open(json.message, " ");              
            }
  
            if(json.status === "error"){
              setSnackRightEOpen(true);
              setSnackEError(json.message)
            }
            
          }

        }catch(err){
          setSnackRightEOpen(true);
          setSnackEError(err);
        }
      }


      const topupValidation = async(e) => {
        try{
          const url = process.env.REACT_APP_UAPI_URL
          const api = `${url}/api/TopupValidation`;
          const method = "POST";
          const bodyData = { "card_id":e };
  
          const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });
  
  
          if(json){
            if(json.status === "success" && json.statuscode === 200 ){
              setTopupref(json.data?.topupdetails.ref_no);
              setTopupCard(json.data?.topupdetails.card_blnc);
              setTopupMobile(json.data?.topupdetails.mobile_no);
              setMaxAmount(json.data.topupdetails?.amnt);
            }
          }
          if(json.status === "error"){
            setSnackRightEOpen(true);
            setSnackEError(json.message);
          }

        }catch(err){
        }
      }

      const makeTopup = async() => {

        if(topupAmount > maxAmount ){
          setSnackRightEOpen(true);
            setSnackEError(`Please Initiate Topup Upto ₹ ${maxAmount} `);
            return;
        }
        else if(topupAmount < 100 ){
          setSnackRightEOpen(true);
            setSnackEError(`Minimum Topup Criteria Is ₹ 100.`);
            return;
        }
        else if(topupAmount === ""){
          setSnackRightEOpen(true);
          setSnackEError(`Try Entering Valid Amount Upto ₹ ${maxAmount} `);
          return;
        }

        try{
          const url = process.env.REACT_APP_UAPI_URL
          const api = `${url}/api/orderInitiate`;
          const method = "POST";
          const bodyData = {
            "user_id": Cookies.get("loginNo"),
            "productId": "0",
            "json": null,
            "quantity": "1",
            "denomination": "0",
            "discount_amt": 0,
            "discount": 0,
            "final_amt":  topupAmount,
            "fees": 0,
            "feeAmount":0,
            "cdID": entityId,
            "reqType":"topup"
        }


          const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

          if(json){
            if(json.status === "success" && json.statuscode === 200 ){
              setSnackRightOpen(true)
              setSnackMessage(json.message);
              setLowBalance(true);
              console.log("low balance data : ",json.data);
              setLowbalanceData(json.data);
            }

            if(json.status === "error"){
              setSnackRightEOpen(true);
              setSnackEError(json.message);
              setLowBalance(true);
              setLowbalanceData(json.data);

              // setTopupAmount("");
              // setTopupref("");
              // setTopupMobile("");
              // setTopupCard("");
            }
          }

        }catch(err){

        }
      }

      console.log()

  function decideRole( e, f, api, redirect ){
    try{
   
       if(e === "Card Lock/Unlock"){
         setEntityId(f)
         setDialogCardDetail(true);
        //  setLockApi( `${process.env.REACT_APP_UAPI_URL}${api}`);
         handleCardLockUnlock(f, api );
       }
   
       if(e === "Card Balance" ){
        
         fetchBalance(f, `${process.env.REACT_APP_UAPI_URL}${api}` );
       }
   
       if(e === "Card Details" ){
         getCardDetails(f, `${process.env.REACT_APP_UAPI_URL}${api}`)
       }
   
       if(e === "Card Transaction"){
         fetchTransitions(f, `${process.env.REACT_APP_UAPI_URL}${api}`, redirect)
         setCardTransId(f);
       }
   
       if(e === "Card SetLimit"){
         getStatus(`${process.env.REACT_APP_UAPI_URL}${api}`, f);
       }
   
       if(e === "Card Enable Txn"){
        //   setEnableTrans(true);
        //  setEnableTransID(f);
         setCardsLimitId(f)
        //  getCardStatus(f)

        getCardStatus(`${process.env.REACT_APP_UAPI_URL}${api}`, f);

        //  setEnableTxnApi(`${process.env.REACT_APP_UAPI_URL}${api}`);
        //  enableTxn(f, api);
       }
   
       if(e === "Card SetPin" ){
         setCardPin(f,`${process.env.REACT_APP_UAPI_URL}${api}`)
       }

       if(e === "Card KYC"){
        kycFormData(f);
       }  

       if(e === "Convert to Physical"){
        convertPhysical(f, `${process.env.REACT_APP_UAPI_URL}${api}` );
       }


       if(e === "Card Load"){
        console.log("e : ", e, "    f : ",f);
        topupValidation(f)
        setEntityId(f);
        setOpenTopup(true);
       }

    }catch(err){

    }
   // e = data.Action && f = row.id
    
  }

  const convertCard = async(f , api) => {

    try{
      setOpenBackdrop(true);

      const url = process.env.REACT_APP_UAPI_URL;
      const method = "POST";
      const bodyData = { "id":f }
  
      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });
      setOpenBackdrop(false);
  
      setPhyProgress(false);
      if(json){
        if(json.status === "success"&& json.statuscode === 200 ){
          setSnackRightOpen(true)
          setSnackMessage(json.message);
          cardData();
          setTimeout(() => {
            eraseConvertData();
            setConvertPhy(false)
          },800)
        }
  
        if(json.Status === "Error"){
          setSnackRightEOpen(true);
          setSnackEError(json.message)
        }
      }
    }catch(err){  
      setSnackRightEOpen(true);
      setSnackEError(err)
    }

    

  }

  const eraseConvertData = () => {
    setAdd1('');
    setAdd2('');
    setAdd3('');
    setPhyCity('');
    setPhyState('');
    setPhyPin('');
  }

  const convertPhysical = (f, api) => {
    // setConvertPhy(true);
    // setPhyApi(api);
    // setPhyId(f);

    convertCard(f , api)
  
  }

  const paginationStyle = {
    padding:20,
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleCatClick = (e) => {
    setCatClick(e);
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if(catClick === '' ){
      setCatClick("Eezib Transorp")
    }
  },[catClick])

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 82,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(45px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#385dff' : '#385dff',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  const cardData = async() => {
    try{
      setFinalTable(true);

      const url = process.env.REACT_APP_UAPI_URL

      const apiUrl = `${url}/api/cardType`;
      const method = "POST";
      const bodyData = {"cardType":checked}
  
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });
  
      
      setFinalTable(false);
  
      if(json){
        if(json.status === "success" && json.statuscode === 200 ){
          setTableData(json.message);
          //openConsentForm(json.Message);
        }
        if(json.status === "error"){
          setTableData(null);
        }
      }
    }catch(err){
      setSnackRightEOpen(true);
            setSnackEError(err);
            Cookies.remove("demoCAuth")
            navigate("/", { replace : true });
    }

  }

  React.useEffect(() => {
    cardData();
  },[checked]);

 // console.log("sliced data : ",tableData);


 const openConsentForm = async(e) => {
  setCurrentData(e);
  setVerifyUser(e.name);
  setVerifyEmail(e.email_id);
  setverifyDob(e.dob);
  setVerifyAddress(e.PER_ADDRESS1);
  setVerifyAddress2(e.PER_ADDRESS2);
  setVerifyAddress3(e.PER_ADDRESS3);
  setCity(e.PER_CITY);
  setState(e.PER_STATE);
  setPinCode(e.PER_PIN);
  setOpenBackdrop(true)

  const url = process.env.REACT_APP_UAPI_URL
  const apiUrl = `${url}/api/viewConsent`;
  const method = "POST";
  const bodyData = { "id":e.id }


  const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchApi = await fetch(`${url}/api/viewConsent`, {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({ "id":e.id })
  // });
  // const json = await fetchApi.json();
  setOpenBackdrop(false);


  if(json){
    if(json.status === "success" && json.statuscode === 200 ){
      setOpenConcent(true);
      setConcentData([json.message])
      // <Box src={json.messafe} component="img" sx={{ backgroundSize:"cover", position:"absolute", backgroundPosition:"center center" }} />
    }
    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message);
    }}}

 async function handleActivateCard(e){
  setProgress(true);
  setOpenBackdrop(true);

  const url = process.env.REACT_APP_UAPI_URL
  
  try{

    const url = process.env.REACT_APP_UAPI_URL
  const apiUrl = `${url}/api/Transregistration`;
  const method = "POST";
  const bodyData = {"id":e}


  const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchApi = await fetch(`${url}/api/Transregistration`, {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({"id":e})
  // });
  // const json = await fetchApi.json();
  setProgress(false);
  setOpenBackdrop(false);
  console.log("activate card json : ",json);
  if(json){
    if(json.status === "error"){
      setSnackRightEOpen(true);
      setSnackEError(json.message)
      setProgress(false)
    }
    if(json.status === "success" && json.statuscode === 200 ){
      setSnackRightOpen(true)
      setSnackMessage(json.message);
      cardData();
    }
  }
  }catch(err){
    setSnackRightEOpen(true);
      setSnackEError(err)
  }

}

  const isDark = Cookies.get("darkMode");

  const verifyuserData = async(e) => {

    e.preventDefault();

    try{
      // const fetchApi = await fetch(`${progress.process.env.REACT_APP_UAPI_URL}/api/${currentData.url}`)
      setVerifyProg(true);
      const fetchData = await fetch(`${process.env.REACT_APP_UAPI_URL}${currentData?.url}`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({ 
          "id":currentData?.id,
          "name":verifyUser,
          "email":verifyEmail,
          "dob":verifyDob,
          "addressinfo":[{ "address1": verifyAddress , "address2": verifyAddress2, "address3":verifyAddress3, "city":city, "state":state, "pincode":pincode }]
         })       
        })
        const json = await fetchData.json();
        setVerifyProg(false);
        if(json){
          if(json.status === "success" && json.statuscode === 200 ){
              setotpVerify(true);
              setVerifyDataDialog(false);
              setOtpEntityId(json.message.sessionId);
              Cookies.remove("userName");
              Cookies.set("userName",json?.data.name)
              setVerifyAddress("");
              setVerifyAddress2("");
              setVerifyAddress3("");
              setPinCode("");
              setState("");
              setCity("");
              cardData();
          }
          if(json.status === "error"){
            setSnackRightEOpen(true);
            setSnackEError(json.message)
            setProgress(false)
          }
        }
    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err)
      setProgress(false)
    }

  }

  // const url = process.env.REACT_APP_UAPI_URL


  const handleOtpVerify = async() => {
    setOtpProg(true);

    const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/cardActivation`;
    const method = "POST";
    const bodyData = { "id":currentData?.id, "otp":otpValue, "sessionId":otpEntityId ? otpEntityId : ""   }
  
  
    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

    // const fetchData = await fetch(`${url}/api/cardActivation`, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":currentData?.id, "otp":otpValue, "sessionId":otpEntityId ? otpEntityId : ""   })
    // }); 
    // console.log({ "id":currentData?.id, "otp":parseInt(otpValue), "sessionId":otpEntityId ? otpEntityId : "" });
    // const json = await fetchData.json();
   

    setOtpProg(false);

    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
          setSnackRightOpen(true);
          setSnackMessage(json.message)
          setOtpValue("");
          setTimeout(() => {
            setotpVerify(false);
            cardData();
          },1000 )
      }
      if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
        setOtpValue("");
      }
    }


  }

  const errorMsg = () => {
    setSnackRightEOpen(true);
    setSnackEError("Technical issue, We are fixing it, Try again in sometime ")
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


  const handleOpenKyc = () => {
    setOPenKYC(false);
    setOpenBackdrop(false);
  }

  const handlePinCode = (e) => {

    const value = e.target.value;
    const regex = /^\d{0,6}$/;

    if(regex.test(value)){
      setPinCode(value)
    }

  }

  console.log("limit data : ",limitData);

  const handleTnxChange = (e) => {
    // (e) => setInputLimitValue(e.target.value)

    const value = e.target.value

    const regex = /^(?:[1-9]\d{0,5}|1000000|0)?$/;

    if(regex.test(value)){
      setInputLimitValue(value);
    }
  }



  const getStatus = async(api , f) => {
    setCardsLimitId(f);
    setOpenBackdrop(true);


    try{

      const method = "POST";
      const bodyData = { "id":f }
    
      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData ,"authAccess":authAccess });


      setOpenBackdrop(false);

      if(json){
        if(json.status === "success" && json.statuscode === 200 ){
          setLimitApiEnd(json.data.url);
          setCardLimitData(json.data);
          setOpenLimit(true);
          setLimitData({
            "atm":json.data.ATM.limit,
            "contactless":json.data.Contactless.limit,
            "pointOfSale":json.data.Point_of_Sale.limit,
            "ecommerce":json.data.Ecommerce.limit
          })
          // setLimitData.atm(json.data.ATM.limit);
          // setLimitData.contactless(json.data.Contactless.limit);
          // setLimitData.pointOfSale(json.data.Point_of_Sale.limit);
          // setLimitData.atm(json.data.Ecommerce.limit);
        }
        else if(json.status === "error"){
          setSnackRightEOpen(true);
          setSnackEError(json.message);
        }
      }

    }catch(err){
    } 
  }
  console.log("ecom limit : ",ecommLimit);

  const setLimits = async(id) => {

    setLimitCardProgress(true)


    try{

      const url = process.env.REACT_APP_UAPI_URL
      const apiUrl = `${url}/${limitApiEnd}`;
      const method = "POST";
      const bodyData = {
      "id": cardsLimitId,
      "data":
      {
        "Ecommerce": {
            "limit": ecommLimit === "" ? limitData?.ecommerce : ecommLimit  ,
        },
        "Point_of_Sale": {
            "limit":  Pos === "" ?  limitData?.pointOfSale : Pos ,
        },
        "ATM": {
            "limit":  Atm === "" ? limitData?.atm : Atm  ,
        },
        "Contactless": {
            "limit": contactless === "" ? limitData?.contactless : contactless ,
        }
    }
      // {
      //     "Ecommerce": {
      //         "limit": ecommLimit >= 100 && ecommLimit !== undefined ? ecommLimit : cardLimitData?.Ecommerce?.limit,
      //     },
      //     "Point_of_Sale": {
      //         "limit": Pos >= 100 && Pos !== undefined ? Pos : cardLimitData?.Point_of_Sale?.limit,
      //     },
      //     "ATM": {
      //         "limit": Atm >= 100 && Atm !== undefined ? Atm : cardLimitData?.ATM?.limit,
      //     },
      //     "Contactless": {
      //         "limit": contactless >= 100  && contactless !== undefined ? contactless :  cardLimitData?.Contactless?.limit ,
      //     }
      // }
  }
  

  // if(  ecommLimit < 100 || cardLimitData?.Ecommerce?.limit < 100 )
  if( ecommLimit ? ecommLimit < parseInt(cardLimitData?.Ecommerce?.minlimit) :  cardLimitData?.Ecommerce?.limit < parseInt(cardLimitData?.Ecommerce?.minlimit)   )
  {
    setSnackRightEOpen(true);
    setSnackEError(`E-Commerce value can't be less than ${cardLimitData?.Ecommerce?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }

  else if( ecommLimit > parseInt(cardLimitData?.Ecommerce?.maxlimit) || parseInt(cardLimitData?.Ecommerce?.limit) >  parseInt(cardLimitData?.Ecommerce?.maxlimit) )
    {
      setSnackRightEOpen(true);
      setSnackEError(`E-Commerce Value Cannnot Be Greater Then ${cardLimitData?.Ecommerce?.maxlimit}`);
      setLimitCardProgress(false);
      return;
    }

  // else if( Pos < 100 || cardLimitData?.Point_of_Sale?.limit < 100 ){
    if( Pos ? Pos < parseInt(cardLimitData?.Point_of_Sale?.minlimit) :  cardLimitData?.Point_of_Sale?.limit < parseInt(cardLimitData?.Point_of_Sale?.limit) ){

    setSnackRightEOpen(true);
    setSnackEError(`Point Of Sale Value Can't be Less Then ${cardLimitData?.Point_of_Sale?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }

  else if( Pos > parseInt(cardLimitData?.Point_of_Sale?.maxlimit) || cardLimitData?.Point_of_Sale?.limit >  parseInt(cardLimitData?.Point_of_Sale?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Point Of Sale value Cannot be Greater than ${cardLimitData?.Point_of_Sale?.maxlimit}`);
    setLimitCardProgress(false);
    return;
  }



  else if( Atm > parseInt(cardLimitData?.ATM?.maxlimit) || cardLimitData?.ATM?.limit > parseInt(cardLimitData?.ATM?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`ATM value can't be Greater Then ${cardLimitData?.ATM?.maxlimit}`);
    setLimitCardProgress(false);
    return;
  }

  // else if( Atm < 100 || cardLimitData?.ATM?.limit < 100 ){
    if( Atm ? Atm < parseInt(cardLimitData?.ATM?.minlimit) :  cardLimitData?.Atm?.limit < parseInt(cardLimitData?.ATM?.minlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`ATM value can't be less than ${cardLimitData?.ATM?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }


  // else if( contactless < 100 || cardLimitData?.Contactless?.limit < 100 ){
    if( contactless ? contactless < parseInt(cardLimitData?.Contactless?.minlimit) :  cardLimitData?.Contactless?.limit < parseInt(cardLimitData?.Contactless?.minlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Contactless value can't be less than ${cardLimitData?.Contactless?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }

  else if( contactless > parseInt(cardLimitData?.Contactless?.maxlimit) || cardLimitData?.Contactless?.limit > parseInt(cardLimitData?.Contactless?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Contactless Value Cannot Be Greater Than ${cardLimitData?.Contactless?.maxlimit}`);
    setLimitCardProgress(false);
    return;
  }

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });
    setLimitCardProgress(false);

    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
        if(json.message.Ecommerce){
          setSnackRightOpen(true);
          setSnackMessage(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
        }
        
        if(json.message.Contactless){
          setTimeout(() => {         
            setSnackRightOpen(true);
            setSnackMessage(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
          }, 2000)
        }

        if(json.message.ATM){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
            setOpenLimit(false);
          }, 3200)
        }


        if(json.message.Point_of_Sale){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
          }, 4500)
        }

      }
      if(json.status === "error"){

        if(json.message){
          setSnackRightEOpen(true);
          setSnackEError(json.message);
        }
       
        if(json.message.Ecommerce){
          setSnackRightOpen(true);
          setSnackEError(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
        }

        if(json.message.Contactless){
          setTimeout(() => {         
            setSnackRightOpen(true);
            setSnackEError(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
          }, 2000)
        }


        if(json.message.ATM){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackEError( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
            setOpenLimit(false);
          }, 3200)
        }

        if(json.message.Point_of_Sale){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackEError( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
          }, 4500)
        }
      }

    }

  }catch(err){
  }
}

  const  marks1 = [
    {
      value:100
    },
    {
      value : cardLimitData?.Ecommerce?.limit
    }
  ]


  const getCardStatus = async(api , f) => {

    setOpenBackdrop(true);

    const apiUrl = api
    const method = "POST";
    const bodyData = {  "id": f }

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });
    
    setOpenBackdrop(false);

    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
        setStatusApiEnd(json.data.url);
        setEnableTxnData(json.data);
        setEnableTrans(true);

        if( json.data.Ecommerce.status === true ){
          setEomCheck(1)
        }
        else if ( json.data.Ecommerce.status === false ) {
          setEomCheck(0)
        }

         if( json.data.Point_of_Sale.status === false ){
          setPosCheck(0)
        }

        else if( json.data.Point_of_Sale.status === true ){
          setPosCheck(1)
        }

         if( json.data.ATM.status === true ){
          setAtmCheck(1)
        }

        else if( json.data.ATM.status === false ){
          setAtmCheck(0)
        }

         if( json.data.Contactless.status === false ){
          setContactlessCheck(0)
        }

        else if( json.data.Contactless.status === true ){
          setContactlessCheck(1)
        }

      }
      if(json.status === "error"){

        if(json.message){
          setSnackRightEOpen(true);
          setSnackEError(json.message);
        }
       
        if(json.message.Ecommerce){
          setSnackRightEOpen(true);
          setSnackEError(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
        }

        if(json.message.Contactless){
          setTimeout(() => {         
            setSnackRightEOpen(true);
            setSnackEError(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
          }, 2000)
        }


        if(json.message.ATM){
          setTimeout(() => {
            setSnackRightEOpen(true);
            setSnackEError( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
            setOpenLimit(false);
          }, 3200)
        }

        if(json.message.Point_of_Sale){
          setTimeout(() => {
            setSnackRightEOpen(true);
            setSnackEError( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
          }, 4500)
        }
      }
    }

  }


  const setTransactionEnable = async() => {

    try{

  
  
      setLimitCardProgress(true)
  
  
      const url = process.env.REACT_APP_UAPI_URL
      const apiUrl = `${url}/${statusApiEnd}`;
      const method = "POST";
      const bodyData = {  
        "id":cardsLimitId,
        "data":{
        "Ecommerce": {
              "status": EcomCheck === 1 ? true : false,
          },
          "Point_of_Sale": {
              "status": PosCheck === 1 ? true : false,
          },
          "ATM": {
              "status": atmCheck === 1 ? true : false,
  
          },
          "Contactless": {
              "status": contactlessCheck === 1 ? true : false,
          }
      }
    }
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });
  
  
      setLimitCardProgress(false);
  
      if(json){
        if(json.status === "success" && json.statuscode === 200 ){



          
          if(json.message.Ecommerce){
            setSnackRightOpen(true);
            setSnackMessage(`E-Commerce ${json.message.Ecommerce ? json.message.Ecommerce : null }`)
            setTimeout(() => {
              setEnableTrans(false)
              setSnackRightOpen(false);   
            },1500)
          }

  
          if(json.message.Contactless){
            setTimeout(() => {         
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage(`Contactless ${json.message.Contactless}`)
            }, 2000)
          }
  
  
          if(json.message.ATM){
            setTimeout(() => {
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage( ` ATM ${json.message.ATM}` )
            }, 4500)
          }
  
          if(json.message.Point_of_Sale){
            setTimeout(() => {
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage( ` Point_of_Sale ${json.message.Point_of_Sale}` )
            }, 6000)
          }
  
        }
        if(json.status === "error"){
       
          if(json.message.Ecommerce){
            setSnackRightEOpen(true);
            setSnackEError(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
          }
  
          if(json.message.Contactless){
            setTimeout(() => {         
              setSnackRightEOpen(true);
              setSnackEError(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
            }, 2000)
          }
  
  
          if(json.message.ATM){
            setTimeout(() => {
              setSnackRightEOpen(true);
              setSnackEError( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
              setOpenLimit(false);
            }, 3200)
          }
  
          if(json.message.Point_of_Sale){
            setTimeout(() => {
              setSnackRightEOpen(true);
              setSnackEError( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
            }, 4500)
          }
        }
      }
    }catch(err){
    }


  }

  const handleEcomLimmit = (e) => {
    const value = e.target.value;
    const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

    // Allow the user to clear the input
    if (regex.test(value)) {
        setEcomLimit(value);
    }
};

const handlePosLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setPos(value);
  }
}

const handleAtmLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setAtm(value);
  }
}

const handleContactLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setContactless(value);
  }
}

  const handleFocus = (event)  => {
    event.target.select();
}

const downloadTransaction = async() => {

            {/* TransfetchtransactionGpr */}

            setTxnProg(true);

            const url = process.env.REACT_APP_UAPI_URL
            const apiUrl = `${url}/api/TransfetchtransactionGpr`;
            const method = "POST";
            const bodyData = {
              "id":cardTransId,
              "fromDate":startDate,
              "toDate":endDate
          }

       const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });


       setTxnProg(false);

       if(json){
        if(json.status === "success" && json.statuscode === 200 ){
          setReportData(json.message)
        }

        if(json.status === "error"){
          setSnackRightEOpen(true);
          setSnackEError(json.message)
        }
       }


}

const headers = Object.keys( transSlicedData ? transSlicedData[0] : {}); 


const generateExcel = () => {
  if (reportData?.length === 0) {
    alert("No data available to generate Excel");
    return;
  }

  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(reportData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Write the workbook to a file
  XLSX.writeFile(workbook, 'tnx download.xlsx');

  // alert("Excel file generated successfully");
};



const generatePdf = () => {
  if (reportData?.length === 0) {
    alert("No data available to generate PDF");
    return;
  }

  const pdf = new jsPDF();
  const keys = Object.keys(reportData[0]);

  // Set initial x and y position for content
  let xPos = 10;
  let yPos = 10;

  // Set cell width and height
  const cellWidth = 22;
  const minHeight = 8;

  // Set font size
  const fontSize = 6;
  pdf.setFontSize(fontSize);

  // Add title
  const title = "Eezib Transaction Data";
  const titleFontSize = 15;
  pdf.setFontSize(titleFontSize);
  const pageWidth = pdf.internal.pageSize.getWidth();
  const titleWidth = pdf.getTextDimensions(title).w;
  const titleXPos = (pageWidth - titleWidth) / 2;
  pdf.text(titleXPos, yPos, title);

  // Adjust y position to start adding table data after title
  yPos += titleFontSize + 10; // 10 units of padding below the title

  // Add headers
  pdf.setFontSize(fontSize);
  keys.forEach((key, index) => {
    pdf.rect(xPos, yPos, cellWidth, minHeight, 'S');
    pdf.text(xPos + 1, yPos + 5, key);
    xPos += cellWidth;
  });

  // Reset x position and increment y position
  xPos = 10;
  yPos += minHeight;

  // Add data
  reportData?.forEach(data => {
    keys.forEach((key, index) => {
      const value = data[key] || '';
      const valueLines = pdf.splitTextToSize(value.toString(), cellWidth - 2);
      const cellHeight = Math.max(minHeight, valueLines.length * fontSize / 2); // Adjust for font size
      pdf.text(xPos + 1, yPos + 5, valueLines);
      pdf.rect(xPos, yPos, cellWidth, cellHeight, 'S');
      xPos += cellWidth;
    });
    xPos = 10;
    yPos += minHeight;
  });

  pdf.save('tnxData.pdf');
};

  const handleCross = () => {
    setDownloadTnx(false);
    setStartDate("");
    setEndDate("");
    setReportData()
  }


  
  const handleWalletRequest =  async() => {

    setProgress2(true);

    const userId = Cookies.get("loginNo");

    const api = env.REACT_APP_UAPI_URL;
    const apiUrl = `${api}/api/order`;
    const method = "POST";
    const bodyData = { "user_id":userId?.toString() , "order_id":lowBalanceData.order_id.toString(), "is_upi": selectedValue === "b" ? 1 : 0, "is_wallet":proceedCoin, "wallet_balance":lowBalanceData?.wallet_amount,  };

    // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }
    
    const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData, "authAccess":authAccess });

    setProgress2(false);


    if(json){
        if(json.status === "success" && json.statuscode === 200 ){
            setHandleSuccess(true);
            setJsonSuccess(json.message);

            setLowBalance(false);
                handleSDialogue();
                
            setTimeout(() => {
                setLowBalance(false);
                setOpenQr(false)
                setIsActive(false);
                setOpen(false);
                // window.location.reload();
            },5000)
        }

        if(json.status === "error"){
          setSnackRightEOpen(true);
          setSnackEError(json.message);     
          setLowBalance(false);
          setSelectedValue("");   
        }
    }

  }


  const handleCheck = (event) => {
    const check = event.target.checked;
   console.log("check  ",check);
    if(check){
     setProceedCoin(1);
     setCheck(true)
    }else{
        setProceedCoin(0);
        setCheck(false)
    }
  }


  const handleWalletBalance = async() => {

    console.log("handle wallet balance called");

        const url = process.env.REACT_APP_UAPI_URL;
        const apiUrl = `${url}/api/b2cUserWallet`;
        const method = "GET";
        const authToken = Cookies.get("voucherDemoAuth");

        
        const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });

        if(json){
            if(json.status === "success" && json.statuscode === 200 ){
                const currentWallet = json.data?.walletbalance;
                  // window.alert(openText)
                  
                  // window.alert(` ${parseFloat(currentWallet)} >= ${ parseFloat(lowBalanceData?.order_amount) + parseFloat(lowBalanceData?.wallet_amount)} =  ${parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount) }`);
                  // window.alert(parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.topup_amount) + parseFloat(lowBalanceData?.order_amount) )
                if( parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount)  ){
                            placeQrOrder(json.data?.walletbalance);
                }else{
                  return;
                }

          
                }        

            if(json.status === "error"){
                setHandleError(true);
                setJsonError(json.message);

                setLowBalance(false);
                setOpenQr(false)
                setIsActive(false);
                setOpen(false);
            }
        }
   }
    

   const   placeQrOrder = async(newBal) => {

    console.log('place order called..');

    try{
      
   setOpenQr(false)
   setIsActive(false);
   setOpen(false);
   
   setProgress3(true);
   setProgress2(true);
   
   // window.alert( ` ${newBal - lowBalanceData?.order_amount >= lowBalanceData?.wallet_amount}, ${lowBalanceData?.wallet_amount} `);  
  
  const userId = Cookies.get("loginNo");
  
  const api = env.REACT_APP_UAPI_URL;
  const apiUrl = `${api}/api/order`;
  const method = "POST";
  const bodyData = { "user_id":userId?.toString() , "order_id":lowBalanceData.order_id?.toString(), "is_upi": selectedValue === "b" ? 1 : 0, "is_wallet":proceedCoin, "wallet_balance":lowBalanceData?.wallet_amount };
  
  // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }
  
  const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData, "authAccess":authAccess, "user_id":userId });
  
  setProgress3(false);
  setProgress2(false);
  
  if(json){
    if(json.status === "success" && json.statuscode === 200 ){
        setHandleSuccess(true);
        setJsonSuccess(json.message);
        setLowBalance(false);
        handleSDialogue();
        setTimeout(() => {
            setLowBalance(false);
            setOpenQr(false);
            setIsActive(false);
            setOpen(false);
            window.location.reload();
        },5000)
    }
  
    if(json.status === "error"){
        setSnackRightEOpen(true);
        setSnackEError(json.message);
       
    }
  }
  
    
      
    }catch(err){

    }

 }



  const jaiGateway = async() => {

    setProgress(true)

    const usrId = Cookies.get("voucherDemoUserId");

    const api = env.REACT_APP_UAPI_URL;
    const apiUrl = `${api}/api/b2c_gateway`;
    const method = "POST";
    const bodyData = { "user_id":usrId, "amount": lowBalanceData?.topup_amount ? lowBalanceData?.topup_amount : denomination, "order_id":lowBalanceData.order_id.toString() }
    // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }
    
    const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData , "authAccess":authAccess });


    setProgress(false)

    if(json){
        if(json.status === "success" && json.statuscode === 200 ){
            setPgUrl(json.data?.url);
            setHandleSuccess(true);
            setJsonSuccess(json.message);
            setLowBalance(false);
            setOpenUrlDialogue(true)
        }

        if(json.status === "error"){
            setHandleError(true);
            setJsonError(json.message);
        }
    }
  }


  
  const   handleQr = async( data ) => {

    console.log("handle qr data code : ",data);
    // 0 == qr and 1 == qr and wallet
    const getQr = sessionStorage.getItem("voucherDemoQr");
    setQrCode(getQr);
    const userNumber =  Cookies.get("userPhone");
    setQrName(userNumber);

    const userName =  Cookies.get("userName");
    setQrName(userName);

    if(data === 0){

      setOpenQr(true);
      setIsActive(true);
      // handleWalletBalance();
    }
    else if(data === 1){
      setOpenQr(true);
      setIsActive(true);
   }
   }

   React.useEffect(() => {
    let apiInterval = null;
    if (openQr && isActive && selectedValue === 'c' && proceedCoin === 0 ) {
        // Initial API call
        handleWalletBalance();
        // Set up an interval to call the API every 7 seconds
        apiInterval = setInterval(() => {
            if (seconds > 0) {
                handleWalletBalance();
            }
        }, 7000);
    }

    else if(openQr && isActive && selectedValue === 'c' && proceedCoin === 1 ){
      // Initial API call
      handleProceed();
      // Set up an interval to call the API every 7 seconds
      apiInterval = setInterval(() => {
          if (seconds > 0) {
            handleProceed();
          }
      }, 7000);
  }
    // Cleanup the interval when the component unmounts or when dependencies change
    return () => {
        if (apiInterval) {
            clearInterval(apiInterval);
        }
    };
}, [openQr, isActive , selectedValue, proceedCoin ]);


   const handleExceptErr =  async() => {
    setHandleError(true);
    setJsonError("Please choose valid payment option.");
}


const handleProceed = async() => {
  console.log("handle proceed called");   
     const getQr = sessionStorage.getItem("voucherDemoQr");
     setQrCode(getQr);

     console.log("Is Active : ",isActive, "  open qr : ",openQr);
            
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/b2cUserWallet`;
      const method = "GET";
      
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });

      if(json){
          if(json.status === 'success' && json.statuscode === 200 ){
              // if(json.data?.walletbalance < lowBalanceData?.order_amount ){
              //     if( seconds > 0 ){
              //         setTimeout(() => {
              //             handleProceed();
              //         },7000)
              //     }
              // }
              if( parseFloat(json.data?.walletbalance) >= parseFloat(lowBalanceData?.order_amount) ){
                handleWalletRequest();     
                // window.alert(`current wallet : ${typeof(json.data.walletbalance)} ${json.data.walletbalance} and order amount ${typeof(lowBalanceData?.order_amount)} ${lowBalanceData?.order_amount}`);        
              }else{
                return
              }
          }
          else if(json.status === "error"){
              setHandleError(true);
              setJsonError(json.message);
              setTimeout(() => {
                  setQrCode(getQr);
                  setOpenQr(true);
                  setIsActive(true);
              },1200)
          }
      }   
  else{
      window.alert("Re-Initiate order.")
      setLowBalance(false);
      setSelectedValue("");
  }
 }

console.log("selected value : ",selectedValue)


  return (
    <Box className="topupContainer">
        <Header svgData={ svgData } />
        <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:"column" }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:1, margin:"1.2rem" }} >
        <Typography sx={{ fontFamily:"montserrat", fontWeight: checked ? 500  :600, color: checked ?  "black" : "#5c1880", textShadow: checked ?  null : '1px 2px 10px white', fontSize:{xs:"0.8rem", lg:"1.2rem"} }}  >Prepaid Card</Typography>


    <Switch
              className="switch"
              checked={checked===1}
              onChange={handleCheckedSwitch}
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#e3e3e3',
                  '&:hover': {
                    '--Switch-trackBackground': '#e3e3e3',
                  },
                },
              })}
            />  


        <Typography sx={{ fontFamily:"montserrat", fontWeight: checked ? 600 : 500, color: checked ? "#5c1880" : "black", textShadow: checked ? '1px 2px 10px white': null, fontSize:{xs:"0.8rem", lg:"1.2rem"} }}  >Reloadable Card</Typography>

        </Box>

        


        

        {
          finalTable ? <CircularProgress size={100} thickness={1} sx={{ marginTop:"2rem" }} /> :   <> <TableContainer elevation={16} component={Paper} sx={{ marginTop:1.5, width:{lg:"88%", xs:"95%" }}} >
          <Table sx={{ backgroundColor:'white' }} aria-label="simple table">
              <TableHead>
              <TableRow sx={{ backgroundColor:'#219bff' }}>
                  <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }} align="center"  >Sr. No</TableCell>
                  <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }} align="center"  >Activation Date</TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" }, display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)}  align="center">Card Name<ExpandMoreIcon/></TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }}  align="center">Card Type</TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }}  align="center">Kit No</TableCell>


                  {/* <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">subcategory</TableCell> */}
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" },  position:{xs:"sticky", md:null, lg:null}, right:{xs:0, md:null, lg:null}, zIndex:{xs:3, md:null, lg:null} , backgroundColor:{xs:"#2196f3", md:"transparent", lg:"transparent"} }}  align="center">Action</TableCell>
              </TableRow>
              </TableHead>
              
              <TableBody>
               { 
              Array.isArray(  slicedData ) && slicedData?.map((row, index) => {
                    let actions = null;
                    try{
                      actions = row.json_format ? JSON.parse(row.json_format) : null;
                    }catch(err){
                      window.alert(err)
                    }
                   
                return (
                      <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{  startIndex + index + 1}</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{ row.activation_date }</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{row.category_name}</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{row.card_type} Card</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:row.REF_NO ? "0.9rem" : "0.8rem" , color:!row.REF_NO ? "#adadac":"black" }}  >{ row.REF_NO ? row.REF_NO : "--"}</TableCell>

                          {/* <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:600 , fontSize:"0.9rem"  }}  >{ row.sub_category_name  }</TableCell> */}
                          <TableCell onClick={() => navigate()} align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.9rem",  position:{xs:"sticky", md:null, lg:null}, right:{xs:0, md:null, lg:null}, zIndex:{xs:3, md:null, lg:null} , backgroundColor:{xs:"white", md:"transparent", lg:"transparent"}  }}  > 
                          {/* { row.REF_NO === null ? <Button size='small' variant='contained' onClick={() => handleActivateCard(row.id)} sx={{ fontFamily:'montserrat', fontWeight:500 }} >Activate Card </Button> :    */}
                          { row.REF_NO === null ? <Button size='small' variant='contained' onClick={() => row?.card_name === "Prepaid" ? handleActivateCard(row.id) : row?.card_name === "Relodable" ? openConsentForm(row) : null } sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{xs:"0.5rem", sm:"1rem" , md:"1rem", lg:"1rem", xl:"1rem" } }} >Activate Card </Button> :  
                           (
                            isXs ? <MenuIcon onClick={(event) => handleMenuClick( event, actions, row.id )} sx={{ color:"#969696" }} /> :
                          Array.isArray(actions) && actions?.map((data, index) => {
                           
                           return (
                           <>
                           
                             <Tooltip key={data.Action} title={data.Action} >
                             <Box component='img' onClick={() => decideRole( data.Action , row.id , data.Api , data.PType ) } src={`${process.env.REACT_APP_UAPI_URL}${data.Img}`  } sx={{ width:"1.4rem", marginLeft:1, cursor:'pointer' }} />
                             </Tooltip>
                           </>
                           )
                           } )
                          )
                          }
                          {/* <Tooltip title="Card Details" onClick={() => getCardDetails(row.id)}  >  <Box component='img' src={cardDetails} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip onClick={() => handleLockUnlock(row.entity_id)} title="Card Lock/Unlock" >   <Box component='img' src={blackunblock} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip title="Fetch Transactions" >   <Box component='img' src={fetchBaalance} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip title="Set Pin" >   <Box component='img' src={setpin} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          */}
                          </TableCell> 

                      </TableRow>
                )
              }) 
              } 
              </TableBody>
          </Table>
      </TableContainer>
      {
        !slicedData  ? <Typography sx={{ fontFamily:"montserrat", fontSize:{ lg:"1.7rem", sm:"1.5rem", md:"1.7rem", xs:"1rem" }, fontWeight:700, textTransform:'capitalize', mt:2, color:"#2e61bf", textShadow:'2px 2px 8px white', marginTop:"2rem" }} > no data available at moment..</Typography>  : 
         <Pagination
        style={paginationStyle}
        variant="outlined"
        color="primary"
        count={ Math.ceil(handleUserTable()?.length / 10)}
        page={page}
        onClick={() => window.scroll(0,10)}
        onChange={handleChangePage}
        sx={{ marginBottom:5 }}
          />
      }
      
     

            </> 
        }

      

                </Box>
                {/* <Dialog
                open={dialogCardDetail}
                onClose={handleLockClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <CloseIcon onClick={() => setDialogCardDetail(false)} sx={{ cursor:'pointer',marginLeft:"auto", backgroundColor:'red', color:'white', fontSize:"1.3rem" }} />
                <DialogTitle sx={{ fontFamily:"montserrat", fontWeight:500, textAlign:'center' }} id="alert-dialog-title">
                  {"Change Card Status.."}
                </DialogTitle>
                <DialogContent >

                 <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2 }} >
                 <FormControl sx={{ marginTop:2, width:"20rem" }} >
                    <InputLabel sx={{ fontFamily:"montserrat", fontWeight:500 }} id="demo-simple-select-label">Select Status</InputLabel>
                    <Select
                    sx={{ fontFamily:"montserrat", fontWeight:500 }}  
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lockStatus}
                      label="Select Status"
                      onChange={handleLockChange}
                    >
                      <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} className='commnTextClass' value="L">Lock</MenuItem>
                      <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} value="U">Unlock</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField onChange={(e) => setCardLockReason(e.target.value)} sx={{ width:"20rem", fontFamily:"montserrat", fontWeight:500 }} label='Enter Reason' ></TextField>
                    {
                      cardLockProgress ? <CircularProgress /> : 
                  <Button disabled={ ! (cardLockReason && lockStatus) } fullWidth variant='contained' onClick={() => handleCardLockUnlock()} sx={{ fontFamily:"montserrat", fontWeight:500, marginTop:2 }} >submit form</Button>
                    }

                 </Box>
                </DialogContent>
              </Dialog> */}


              <Dialog
              open={openLimit}
              onClose={handleLimitClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",  // Set your width here
                  },
                },
              }}
            >
              <DialogContent 
              // sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', margin:0, padding:1 }}
               >

               <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', margin:0, padding:0 }} >
                <Box component='img' src={eezibLogo} sx={{ width:"3rem" }} />
                <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1.2rem" }} > Set Card Limit </Typography>
                <CloseIcon onClick={() => setOpenLimit(false) } sx={{ backgroundColor:'red', color:'white',fontSize:'1.2rem', cursor:'pointer'  }} />
               </Box>

               <Divider sx={{ mt:"1rem" }} />
               <Box>

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >
                  <Box component="img" src={ecomImg} sx={{ width:"12rem" }} />
                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"1rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} > E Commerce</Typography> 
                    <Box>
                    <Slider                    
                    //  defaultValue={ cardLimitData?.Ecommerce?.limit }
                    key={cardLimitData?.Ecommerce?.maxlimit}
                    min={cardLimitData?.Ecommerce?.minlimit}
                    max={cardLimitData?.Ecommerce?.maxlimit}
                    aria-label="Small"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChange}
                    value={ecommLimit === undefined ? cardLimitData?.Ecommerce?.limit : ecommLimit }
                    marks={marks1}
                    valueLabelDisplay="auto"
                    disabled={ cardLimitData?.Ecommerce?.applicable === 0 }
                    />    
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Ecommerce?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Ecommerce?.maxlimit} max</Typography>           
                      </Box>   
                    </Box>                   
                <TextField variant='outlined' disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" id="standard-basic" onChange={ handleEcomLimmit } value={ ecommLimit ? ecommLimit : cardLimitData?.Ecommerce?.limit } size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', textAlign:'center' ,"input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }}  />                  
                </Box>

                </Box>

                <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                <Box component="img" src={posImg} sx={{ width:"12rem" }} />

                <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >Point Of Sale</Typography> 
                <Box>

                    <Slider
                    // defaultValue={ cardLimitData?.Point_of_Sale?.limit?.toString() }
                    key={cardLimitData?.Point_of_Sale?.maxlimit}
                    min={100}
                    max={cardLimitData?.Point_of_Sale?.maxlimit}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChangePos}
                    value={Pos === undefined ? cardLimitData?.Point_of_Sale?.limit : Pos  }
                    disabled={ cardLimitData?.Point_of_Sale?.applicable === 0 }

                    />
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Point_of_Sale?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Point_of_Sale?.maxlimit} max</Typography>           
                      </Box> 
                    </Box>
                  <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" onChange={ handlePosLimit } value={ Pos ? Pos : cardLimitData?.Point_of_Sale?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>
                  </Box>

                  <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                    <Box component="img" src={atmImg} sx={{ width:"12rem" }} />   
                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >ATM</Typography> 
                  <Box>

                    
                    <Slider
                    // defaultValue={70}
                    key={ cardLimitData?.ATM?.maxlimit }
                    min={100}
                    max={ cardLimitData?.ATM?.maxlimit }
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChangeAtm}
                    value={Atm === undefined ? cardLimitData?.ATM?.limit :  Atm  }
                    disabled={ cardLimitData?.ATM?.applicable === 0 }

                  />
                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                      <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.ATM?.minlimit} min</Typography>
                     <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{ cardLimitData?.ATM?.maxlimit } max</Typography>           
                      </Box> 
                      </Box>
                  <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number"  onChange={ handleAtmLimit } value={ Atm ? Atm : cardLimitData?.ATM?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>
                  </Box>

                  <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                  <Box component="img" src={contactLess} sx={{ width:"12rem" }} />

                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >Contactless</Typography> 
                      <Box>

                      
                    <Slider            
                      // defaultValue={7000}
                      key={ cardLimitData?.Contactless?.maxlimit }
                      min={100}
                      max={ cardLimitData?.Contactless?.maxlimit }
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                      onChange={handleSliderChangeContact}
                      value={contactless === undefined ? cardLimitData?.Contactless?.limit : contactless }
                      disabled={ cardLimitData?.Contactless?.applicable === 0 }

                    />

                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Contactless?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{ cardLimitData?.Contactless?.maxlimit } max</Typography>           
                      </Box>

                    </Box>
                     <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" onChange={ handleContactLimit } value={contactless ? contactless : cardLimitData?.Contactless?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2',  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>


                </Box>
                </Box>
                  <Button disabled={ limitCardProgress } fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, marginTop:"2rem" }} onClick={(e) => setLimits()}  variant="contained" > { limitCardProgress ? "submitting Data.." : "Submit Data" }</Button>
                    

              </DialogContent>
            </Dialog>













            <Dialog
              open={enableTrans}
              onClose={handleTransClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "400px",  // Set your width here
                  },
                },
              }}
            >
              <CloseIcon onClick={() => setEnableTrans(false) } sx={{ backgroundColor:'red', color:'white',fontSize:'1.2rem', cursor:'pointer', position:"absolute", right:0 }} />
              <DialogTitle sx={{ fontFamily:"montserrat", fontWeight:500 , textAlign:'center'}} id="alert-dialog-title">
                {"Enable Online Transaction"}
              </DialogTitle>
              <Divider variant="middle" />
              <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2,  }} >
              
             <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

             <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > E-Commerce </Typography>
             <Switch
              className="switch"
              checked={EcomCheck === 1}
              onChange={handleEcomCheck}
              disabled={ enableTxnData?.Ecommerce?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />
             </Box>
             

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

            <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > Point Of Sale </Typography>
              
              <Switch
              className="switch"
              checked={ PosCheck === 1 }
              onChange={handlePosCheck}
              disabled={ enableTxnData?.Point_of_Sale?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />

              </Box>



              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

              <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > ATM </Typography>

              <Switch
              className="switch"
              checked={ atmCheck === 1 }
              onChange={ handleAtmCheck }
              disabled={ enableTxnData?.ATM?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />
              </Box>


              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

              <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > Contactless </Typography>

              <Switch
              className="switch"
              checked={contactlessCheck === 1}
              onChange={handleContLessCheck}
              disabled={ enableTxnData?.Contactless?.applicable === 0 }

              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />

              </Box>

               <Button disabled={  limitCardProgress } onClick={() => setTransactionEnable()} variant="contained" fullWidth sx={{ fontFamily:"montserrat", fontWeight:500 }} > { limitCardProgress ? "Loading" : "Submit Data" } </Button>
              
              </DialogContent>
            </Dialog>

                



            <Dialog
              open={openTransTable}
              onClose={handleTransTableClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
               
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "90%", 
                  },
                },
              }}
            >
              <CloseIcon onClick={() => setOpenTransTable(false) } sx={{ backgroundColor:'red', color:'white', position:'absolute', right:0 ,fontSize:'1.2rem', cursor:'pointer' }} />
                <Tooltip title="Download Transaction's" placement='top' >
              <Button variant='contained' endIcon={ <DownloadingIcon size="1.2rem" /> } onClick={() => setDownloadTnx(true)} sx={{ position:'absolute', bottom:0, right:0, margin:1, cursor:"pointer", fontFamily:'montserrat', fontWeight:400 }} >Download Txn</Button>
                </Tooltip>
                <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textTransform:'capitalize', textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.6rem"}, marginTop:"1rem" }} > <span style={{ color:'#4d97ff' }} > {name}'s </span> card transaction data </Typography>
              <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2, width:"96%" }} >
              {
                <TableContainer elevation={16} component={Paper} sx={{ marginTop:0, marginBottom:5 }} >
                    <Table sx={{ minWidth: 800 ,backgroundColor:'white' }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{ backgroundColor:'#219bff' }} >

                        {headers.map((header, index) => (
                          <TableCell align='center' sx={{ color:"white", fontFamily:"montserrat", textTransform:"capitalize", fontSize:"0.7rem" }} key={index}>{header}</TableCell>
                        ))}
                         </TableRow>
                        </TableHead>
                        <TableBody>
                       { Array.isArray(transSlicedData) && transSlicedData?.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {headers.map((header, headerIndex) => (
                            <TableCell sx={{ fontFamily:"Poppins", color:"black", fontWeight:400, fontSize:"0.8rem" }} key={headerIndex}>{row[header]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }
              
              </DialogContent>
            </Dialog>


            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openCatMenu}
            onClose={handleCloseCatMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
              defaultValue="Eezib Transcorp"
          >
            <MenuItem onClick={(e) => handleCatClick("All Cards")} value="eezib" sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib All Cards</MenuItem>
            
            <MenuItem onClick={(e) => handleCatClick("Eezib Transcorp")} value={4}  sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib Transcop</MenuItem>
            {/* <Divider variant='middle' sx={{ padding:0, marrgin:0 }} /> */}
            <MenuItem onClick={(e) => handleCatClick("Eezib Fino Payments Bank")} value={3} sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib Fino PB</MenuItem>

          </Menu>

          <Dialog
          open={openConcent}
          // onClose={handleConcentClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon onClick={() => handleConcentClose() } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:{lg:"1.2rem", sm:"1.2rem", md:"1.2rem", xs:"0.9rem" } , textAlign:'center'}}  >Consent Form</Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent>
          {
            concentData?.map((row) => (
              <Box>

                <Box>  

                <Box sx={{ display:"flex" }} >
                  
                <Checkbox sx={{ float:'right' }} onChange={handleCheck} color='primary' checked={check} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography sx={{ fontFamily:"Poppins", fontWeight:400, fontSize:{lg:"1rem", sm:"1rem", md:"1rem", xs:"0.8rem" }, color:"#525252" }} >{row.consent}</Typography>
                </Box>
                {
                  row.concent2 ? 
                  <Typography sx={{ fontFamiy:"montserrat", fontWeight:500, fontSize:{lg:"1rem", sm:"1rem", md:"1rem", xs:"0.8rem" } }} >{row.consent2}  </Typography> : null
                }
                </Box>

                <Box>+
                  {
                    row.consentLink !== null ? <Typography onClick={() => window.open(row.consentLink, "")} sx={{ fontFamily:"Poppins", fontWeight:400, marginTop:"0.5rem", textAlign:'center', mt:2 }} > check out <span style={{ fontFamily:"Poppins", fontWeight:500, textTransform:"capitalize", color:"#43007d", cursor:"pointer" }}>terms & conditions</span> here  </Typography> : null   
                  }
                </Box>

                  <Button fullWidth disabled={ !check } onClick={() => handleOtpDialog() }  variant='contained' sx={{ marginTop:1, fontFamily:"montserrat", fontWeight:400, mt:2 }} > continue  </Button>
             
              </Box>
            ))
          } 
        </DialogContent>
        
      </Dialog>


    
    
      <Dialog
          open={verifyDataDialog}
          onClose={verifyOtpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "60rem",
              },
            },
          }}
        >
          <CloseIcon onClick={() => setVerifyDataDialog() } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f", fontSize:"1.3rem" }} />
        <DialogTitle id="alert-dialog-title">
          {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem", fontSize:"1.2rem" }}  > verify costumer Details </Typography> */}
          <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center' }} >
          <Box component='img' src={eezibLogo} sx={{ width:{lg:"7rem", sm:"7rem", md:"7rem", xs:"4rem" } }}  />
          </Box>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

            <form onSubmit={verifyuserData} >

              <Typography sx={{ fontFamily:"Poppins", fontWeight:600, fontSize:{lg:"1.5rem", sm:"1.5rem", md:"1.5rem", xs:"1rem" }, textAlign:'center', textTransform:"capitalize", color:"#2162cc", marginBottom:"1.2rem", marginTop: isXs ? 5 : 0 }} >verify customer details</Typography>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 2 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" } }} >

              <TextField required value={ verifyUser } onChange={(e)  => setVerifyUser(e.target.value)} variant='outlined' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} sx={{ textTransform:"capitalize", fontSize:"0.7rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize' ,fontSize: isXs ? "0.8rem" : "1rem" } }}  label="Customer Name" />

              <TextField value={ verifyEmail } variant='filled' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , cursor:'not-allowed', pointerEvents:"none", fontSize: isXs ? "0.8rem" : "0.9rem" , fontWeight:500 }, readOnly: true }} label="Email" />

              <TextField value={ verifyDob } variant='filled' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , cursor:'not-allowed', pointerEvents:"none", fontSize: isXs ? "0.8rem" : "1rem"  } }} label="DOB" />

              </Box>

              <Box >
              <Divider variant="middle" sx={{ marginTop:"1rem", fontSize:"0.7rem", color:'#4282eb', textAlign:'center', fontWeight:400 }}> Enter Address As Per Aadhar* </Divider>
              {/* <label style={{ fontSize:"0.7rem", color:'gray', textAlign:'center' }} >Enter Address As Per Aadhar*</label> */}
              </Box>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 1 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" } }} >
              <TextField required value={ verifyAddress } onChange={(e) => setVerifyAddress(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.85rem" } }} fullWidth sx={{ marginTop:3  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 1" />
              <TextField value={ verifyAddress2 } onChange={(e) => setVerifyAddress2(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.9rem" } }} fullWidth sx={{ marginTop:3 }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 2 (Optional) " />
              <TextField value={ verifyAddress3 } onChange={(e) => setVerifyAddress3(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.9rem" } }} fullWidth sx={{ marginTop:3 }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 3 (Optional) " />

            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 2 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" }, mt:3 }} >
           
            <TextField required value={ city } onChange={(e) => setCity(e.target.value)} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  } }} label="City" />
            <TextField required value={ state } onChange={(e) => setState(e.target.value)} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  } }} label="State" />
            <TextField required value={ pincode } onChange={handlePinCode} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  }  }} label="Pincode" />
            </Box>


              <Button disabled={verifyProg} type='submit' variant="contained" fullWidth sx={{ fontFamily:"montserrat", fontWeight:500, marginTop:3 }} >{ verifyProg ? "Loading.." : "Verify" }</Button>

            </form>

          
        </DialogContent>
        
      </Dialog>





      <Dialog
          open={ otpVerify }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon onClick={() => setotpVerify(false) } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:isXs ? "0.8rem": "1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  >enter otp send to your registered mobile number</Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >


              <Box component="img" src={imageLogo} sx={{ width: isXs ? "16rem" : "25rem" }} />

              <TextField type="number"  InputLabelProps={{ style:{ fontFamily:"Poppins", fontWeight:400 } }} onChange={(e) => setOtpValue(e.target.value)} value={otpValue} label="Enter One Time Password"  sx={{ width: isXs ? "16rem" : "23rem", mt:2,  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": { WebkitAppearance: "none",margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  } }}  />

              <Button onClick={() => handleOtpVerify()}  disabled={ otpValue?.length < 4 || otpProg  } variant='contained' sx={{ mt:2, width: isXs ? "16rem" : "23rem",  fontFamily:"Poppins", fontWeight:500 }} >{ otpProg ? "Loading.." : "Submit" }</Button>
          
        </DialogContent>
        
      </Dialog>


      <Dialog
          open={ openKYC }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px",  // Set your width here
              },
            },
          }}
        >
          <CloseIcon onClick={() => handleOpenKyc()  } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  > KYC Verification </Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

          <Box>

            <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center',  flexDirection:{lg:"row", sm:"row", md:"row", xs:"column" }, gap:{lg:5, sm:5, md:5, xs:2 }  }} >

            <TextField type="number" error={aadharNo?.length > 12} helperText={ aadharNo?.length > 12 ? "enter valid aadhar number" : "" }  focused value={aadharNo} onChange={(e) => setAadharNo(e.target.value)} label="Aadhar number"  sx={{ width:"17rem", "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
             WebkitAppearance: "none",
              margin: 0,
              },
            "input[type=number]": {
               MozAppearance: "textfield",
              } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }}  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} 
            />

            <TextField focused value={panNo} onChange={(e) => panNo(e.target.value)} label="Pan Number" sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            </Box>

            <Box sx={{ marginTop:2, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" , gap:2 }} >
            <TextField focused value={fatherName} onChange={(e) => setFatherName(e.target.value)} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Father Name" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
            <TextField focused value={kycAddress} onChange={(e) => setKycAddress(e.target.value)} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Permanent Address" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
              {/* <label style={{ fontSize:"0.8rem", fontWeight:300, color:"#3850c7" }} >*Select Passport Size Image upto 600KB 
                
              </label>
            <input 
          ref={fileInputRef}
          id='file_input'
          accept="image/*"
          type='file' style={{  width:'13rem' }}
          onChange={handleImageChange}
          
          />   */}

              {
              <Button disabled={openBackdrop || !aadharNo || !panNo || !fatherName || !kycAddress || aadharNo?.length !== 12 } onClick={() => handleVerification()} fullWidth variant='contained' sx={{ fontFamily:"montserrat", fontWeight:500 }} >{openBackdrop ? "Loading" : "SUBMIT"}</Button>
              }

                </Box>

          </Box>
          
        </DialogContent>
        
      </Dialog>




      <Dialog
          open={ convertPhy }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px",  // Set your width here
              },
            },
          }}
        >
          <CloseIcon onClick={() => setConvertPhy(false) } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  > Convert To Physical Card form </Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

          <Box>

            <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >

            <TextField value={add1}  onChange={(e) => setAdd1(e.target.value)} label="Address 1" focused sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }}  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}}                 
                      />

            <TextField focused value={add2}  onChange={(e) => setAdd2(e.target.value)} label="Address 2" sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            </Box>

            <Box sx={{ marginTop:2, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column", gap:2 }} >
             
              <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >
            <TextField focused value={add3}  onChange={(e) => setAdd3(e.target.value)} sx={{ width:"17rem"  }} label="Address 3" InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            <TextField focused value={phyCity}  onChange={(e) => setPhyCity(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="City" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />

              </Box>


            <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >
            <TextField focused value={phyState}  onChange={(e) => setPhyState(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="State" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
            <TextField focused value={phyPin}  onChange={(e) => setPhyPin(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Pin" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />

            </Box>
        

              {
              <Button disabled={!add1 || !add2 || !add3 || !phyCity || !phyState || !phyPin || phyProgress } onClick={() => convertCard() } fullWidth variant='contained' sx={{ fontFamily:"montserrat", fontWeight:500 }} >{phyProgress ? "Loading" : "SUBMIT"}</Button>
              }
                </Box>
          </Box>
          
        </DialogContent>
        
      </Dialog>


      <Dialog
        open={downloadTnx}
        onClose={handleDTxnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{
        //   "& .MuiDialog-container": {
        //     "& .MuiPaper-root": {
        //       width: "100%",
        //       maxWidth: "700px",  // Set your width here
        //     },
        //   },
        // }}
      >
        <CloseIcon onClick={() => handleCross() } sx={{ position:"absolute", right:0, color:"red", cursor:'pointer' }} />
        <DialogTitle sx={{ textAlign:'center', fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.8rem": "1rem" }} id="alert-dialog-title">
          {" Download Transaction History "}
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:'column' }} >

            <Box>
              <Box component="img" src={downloadfile} sx={{ width:{lg:"25rem", md:"25rem", sm:'25rem', xs:"18rem" } }} />
            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, flexDirection:{lg:'row', mn:'row', sm:"row", xs:"column" } }} >

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Select Start Date" 
              onChange={(e) => setStartDate(moment(e?.$d).format("YYYY-MM-DD"))}
              format="YYYY-MM-DD"
              disableFuture={true}
                sx={{
                '& .MuiInputBase-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiFormLabel-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
              }}  />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
          <DatePicker label="Select End Date" 
          onChange={(e) => setEndDate(moment(e?.$d).format("YYYY-MM-DD"))}
          format="YYYY-MM-DD"
          disableFuture={true}
            sx={{
              '& .MuiInputBase-root': {
                fontFamily: 'montserrat', fontWeight:500,
              },
              '& .MuiFormLabel-root': {
                fontFamily: 'montserrat', fontWeight:500,
              },
              '& .MuiInputBase-input': {
                fontFamily: 'montserrat', fontWeight:500,
              },
            }}  />
      </DemoContainer>
    </LocalizationProvider>

            </Box>
            <Button  disabled={txnProg || !startDate || !endDate } fullWidth onClick={() => downloadTransaction() } sx={{ fontFamily:'montserrat', mt:2, fontWeight:500 }} variant="contained" > { txnProg ? "fetching Data" : "Fetch Transactions" }</Button>
            <Box sx={{ marginTop:2, width:"100%" }} >
            {
              reportData?.length >= 1 ? <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly' }} >
                <Box onClick={() => generateExcel() } sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' , flexDirection:'column', cursor:"pointer" }} >
                <Box component='img' src={exceLogo} />
                  <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.75rem" : "1rem" }} >Download Excel</Typography>
                </Box>

                <Box onClick={() => generatePdf() } sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' , flexDirection:'column', cursor:"pointer" }} >
                <Box component='img' src={pdfLogo} />
                  <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.75rem" : "1rem" }} >Download PDF</Typography>
                </Box>
                </Box>
               : null
            }
            </Box>
        </DialogContent>
   
      </Dialog>



      {/* *************** Topup Dialogue ****************** */}


      <Dialog
            fullScreen
            open={openTopup}
            onClose={handleTopup}
            TransitionComponent={Transition}
          >
            <AppBar className='passComp' sx={{ position: 'relative' }}>
              <Toolbar sx={{ display:'flex', alignItems:'center',justifyContent:'space-between'  }} >
                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleTopup}
                  aria-label="close"
                  >
                   <CloseIcon />
                </IconButton>
                  <Box component="img" src={ eezibWhite } sx={{ width:"8rem" }} />
                  </Box>

                  <Typography sx={{ fontFamily:'Montserrat', fontWeight:700, fontSize:"2rem" }} >To<span className='changeP' >p</span>u<span className='changeP' >p</span></Typography>
                
              </Toolbar>
            </AppBar>

            <DialogContent>
              {/* <Typography className='idText' sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:{lg:"3rem", sm:"2rem", md:"3rem", xs:"2rem" } , textAlign:'center' }} >eezib </Typography> */}

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', mt:1, flexDirection:{lg:'row',md:"row", sm:"column", xs:"column"  } }} >

                  <Box component="img" src={topupImg} sx={{ width:{lg:"30rem", md:"30rem", sm:"25rem", xs:"25rem" } }} />                 

                  <Paper elevation={18} sx={{ borderRadius:'0.5rem', width:"20rem", display:'flex', alignItems:'center', justifyContent:'center', padding:"1rem 1.6rem", flexDirection:'column' }}  >
                    <Typography sx={{ fontFamily:'Poppins',fontWeight:500, textTransform:'capitalize' }} >Please enter required details</Typography>
                    <Divider sx={{ width:"100%", mt:2 }} />
                    <TextField className='verifyCust' disabled inputProps={{ style:{ fontFamily:"Poppins", fontWeight:'400' } }} variant='filled' label="Reference Number" value={topupRef} sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"18rem"}, mt:2, cursor:'default' }} InputProps={{ style:{ cursor:'not-allowed' } }} InputLabelProps={{ style:{fontFamily:"Poppins", fontWeight:400 } }}  />
                    <TextField className='verifyCust' inputProps={{ style:{ fontFamily:"Poppins", fontWeight:'400' } }} variant='filled' label="Mobile" value={topupMobile} disabled InputProps={{ style:{ cursor:'not-allowed' } }} sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"18rem"}, mt:2, cursor:'default' }} InputLabelProps={{ style:{fontFamily:"Poppins", fontWeight:400 } }}  />
                    <TextField className='verifyCust' inputProps={{ style:{ fontFamily:"Poppins", fontWeight:'400' } }} disabled InputProps={{ style:{ cursor:'not-allowed' } }} variant='filled' label="Card Balance" value={topupCard} sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"18rem"}, mt:2, cursor:'default' }} InputLabelProps={{ style:{fontFamily:"Poppins", fontWeight:400 } }}  />
                      <Typography sx={{ fontFamily:'Poppins', fontWeight:400, color:'gray', fontSize:"0.7rem", mt:1.5 }} >Max Topup Limit - ₹ {maxAmount}</Typography>
                    <TextField type="number" onChange={(e) => setTopupAmount(e.target.value)} value={topupAmount} inputProps={{ style:{ fontFamily:"Poppins", fontWeight:'400' } }} label="Amount" sx={{ width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"18rem"}, mt:1 }} InputLabelProps={{ style:{fontFamily:"Poppins", fontWeight:400 } }}  />
                    <Button variant="contained"  onClick={() => makeTopup()} className='passComp' fullWidth sx={{ fontFamily:'Poppins', fontWeight:400, mt:2, fontSize:"1rem", width:{lg:"20rem", md:"20rem", sm:"18rem", xs:"18rem"}, backgroundColor:'#B721FF' }} >  submit </Button>
                  </Paper> 
                </Box>
           
            </DialogContent>

          </Dialog>



      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    {/* *************** order value ui dialogue *********** */}


      <Dialog
      open={lowbalance}
      aria-describedby="alert-dialog-slide-description"
      sx={{borderRadius:4 ,
          "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth:{lg:"900px", md:"900px ", sm:"900px", xs:"400px"},  // Set your width here
              },
            },
      
        }}
      >
        <CloseIcon onClick={() => handleLowBalance() } sx={{ position:"absolute", right:0, color:'#8c8c8c', cursor:'pointer' }} />   

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-around', flexDirection:{ xs:"column", sm:"row", md:"row", lg:"row"  } }} >
              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
              {/* <Typography sx={{ fontFamily:"montserrat", fontWeight:500, mt:1 }} > Order Summary </Typography> */}
              <Box component="img" src={eezibLogo} sx={{ width:{sm:"5rem", md:"5rem", lg:"7rem" , xs:"7rem"}, mt:1 }} />  
                  <Box component="img" src={pgImage} sx={{ width:{sm:"20rem", md:"25rem", lg:"25rem" , xs:"15rem"} }} />  
              </Box>
              <Box sx={{ }} >
                  <Box sx={{ display:'flex',alignItems:'center', justifyContent:"center", flexDirection:'row'  }}  >

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column' }} >
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Order Value &nbsp;: <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Wallet Value : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Topup Value : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                  </Box>

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column', p:1 }} >
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />    
                  </Box>

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column', p:1 }} >
                  <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" }  }} >
                    {lowBalanceData?.order_amount}
                    </Typography>
                  <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500 , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} >
                    {lowBalanceData?.wallet_amount} 
                    </Typography>
                  { lowBalanceData?.topup_amount === null || 0 ? null : 
                    <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500 , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > 
                      {lowBalanceData?.topup_amount} 
                      </Typography>
                  }
                  </Box>

                      </Box>
                          <Divider fullWidth sx={{ marginTop:"1rem", marginBottom:"1rem" }} />
                      <Box>



                          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2, width:"15rem" }} >
                              {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:"#2a9adb", textTransform:'capitalize', fontSize:"0.8rem", mt:1 }} >Add Money to pay </Typography> */}
                              <Box sx={{ mt:2, border:'1px solid #ababab', width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem"   } , borderRadius:2 }} >

                                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Checkbox
                                  // checked={selectedValue === 'a'}
                                  onChange={handleCheck}
                                  value="a"
                                  name="radio-buttons"
                                  // inputProps={{ 'aria-label': 'A' }}
                                  // disabled={ parseFloat(lowBalanceData?.topup_amount) < 0.01 }
                              />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, textTransform:"capitalize" , color:lowBalanceData?.topup_amount !== 0 ? "gray" : "black" , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem", xs:"0.7rem" } }} >Proceed With Coins</Typography>

                              <Box component='img' src={eeLogo} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                                  {/* {
                                      progress2 ? <CircularProgress/> : 
                              <Button onClick={() => handleWalletRequest() } fullWidth endIcon={ <AccountBalanceWalletIcon/> } disabled={ lowBalanceData?.topup_amount !== null } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem" }} >Purchase from wallet</Button>
                                  } */}
                              {
                                  lowBalanceData?.topup_amount === 0 ? null : 
                              <Typography sx={{ fontFamily:'montserrat',fontWeight:500, fontSize:{lg:"0.7rem" , md:"0.6rem", sm:"0.7rem", xs:"0.6rem" }, color:"#fc5b5b", textAlign:'center' }} > Topup Required - ee 
                              {lowBalanceData?.topup_amount}
                               </Typography> 
                              }
                              </Box>

                              <Box sx={{ border:"1px solid #ababab",  width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , borderRadius:2 }} >

                                  
                              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Radio
                              checked={selectedValue === 'c'}
                              onChange={handleChange}                              
                              checkedIcon={ <RadioButtonCheckedIcon/> }
                              value="c"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'C' }}
                              disabled={ lowBalanceData?.pg_status === 0 }
                              />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{lg:"1rem" , md:"1rem", sm:"0.9rem", xs:"0.8rem" } ,textTransform:"capitalize", color:lowBalanceData?.qr_status === 0 ? "gray" : "black" }} >Scan With QR</Typography>

                              <Box component='img' src={userQr} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                              {/* {
                                  progress ?   <CircularProgress/> :
                                  <Button fullWidth00000p disabled={ lowBalanceData?.pg_status === 0 || progress2 } onClick={() => jaiGateway()} endIcon={ <AccountBalanceIcon/> } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, mt:1 }} > purchase more coins </Button>
                              } */}
                              </Box>

                              <Box sx={{ border:"1px solid #ababab",  width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , borderRadius:2 }} >

                                  
                              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Radio
                              checked={selectedValue === 'b'}
                              onChange={handleChange}
                              value="b"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'B' }}
                              disabled={ lowBalanceData?.pg_status === 0 }
                          />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{lg:"1rem" , md:"1rem", sm:"0.9rem", xs:"0.8rem" } ,textTransform:"capitalize", color:lowBalanceData?.pg_status === 0 ? "gray" : "black" }} >other Options</Typography>

                              <Box component='img' src={cardIcon} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                              {/* {
                                  progress ?   <CircularProgress/> :
                                  <Button fullWidth00000p disabled={ lowBalanceData?.pg_status === 0 || progress2 } onClick={() => jaiGateway()} endIcon={ <AccountBalanceIcon/> } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, mt:1 }} > purchase more coins </Button>
                              } */}
                              </Box>

                                  {
                                      progress || progress2 ? <CircularProgress/> : 
                              <Button disabled={ progress2 } variant='contained' onClick={ () =>  selectedValue === '' && proceedCoin === 1 ? handleWalletRequest() : selectedValue === 'b' ? jaiGateway() : selectedValue === 'c' && proceedCoin === 0 ? handleQr(0) : selectedValue === 'c' && proceedCoin === 1 ? handleQr(1) :  handleExceptErr()  } sx={{ fontFamily:'montserrat', fontWeight:400, width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , mb:{xs:"1rem"} }} > make payment </Button>
                                // <Button disabled={ progress2 } variant='contained' onClick={ () =>  console.log("selected value : ",selectedValue, ".......   Proceed coin : ",proceedCoin)  } sx={{ fontFamily:'montserrat', fontWeight:400, width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , mb:{xs:"1rem"} }} > make payment </Button>
                                  }

                          </Box>
                      </Box>
                  </Box>

              
          </Box>
      
    </Dialog>

    <Dialog
        open={openQr}
        // onClose={handleOpenQr}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius:3 }}
      >
        
        <CloseIcon onClick={handleOpenQr} sx={{ cursor:'pointer', position:'absolute', right:0, color:'red', fontSize:"1.2rem",  }} />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}  >
                <Box component="img" src={eezibLogo} sx={{ width:"9rem" }} />
                <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"0.8rem" }} >QR Accepted Here</Typography>
                <Divider variant='middle' sx={{ width:"100%", mt:1 }} />
          <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem", mt:2 }} >  {sessionStorage.getItem("messageUPI")} </Typography>
       
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', mt:0 }}  >
                  {
                   parseFloat(lowBalanceData?.topup_amount) > 0.01 ? 
                   <Typography sx={{ fontFamily:'Poppins', fontSize:'0.85rem', color:'#eb5757' }} > Topup Required - ₹ {lowBalanceData?.topup_amount} </Typography> : null
                  }                                       
                    <div style={{ width:"15rem" }} dangerouslySetInnerHTML={{ __html: sessionStorage.getItem("userQr") }} />

                    <LinearProgress sx={{ width:"100%", mt:1 }} />

                    <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem", mt:1 }} >  {sessionStorage.getItem("userEmail")} </Typography>
                    <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem" }} >  {sessionStorage.getItem("userPhone")} </Typography>
                    {/* <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"0.8rem", mt:1 }} >Scan & Pay</Typography> */}
                </Box>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', width:"100%", mt:"0.4rem", mb:2  }} >
                    <Box component='img' src="https://cdn.icon-icons.com/icons2/2699/PNG/512/upi_logo_icon_169316.png" sx={{width:"5rem"}} />
                    <Box component="img" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/bhim-app-icon.png" sx={{ width:"6rem" }} />
                </Box>
                    <Divider variant='middle' sx={{ width:"100%" }} />
                <Box sx={{  fontFamily:"Poppins", fontWeight:400, color:'gray', textTransform:"capitalize", fontSize:"0.7rem", mt:1 }} >
                    Session will be expire in {seconds} seconds
                </Box>

            </Box>
        </DialogContent>
      </Dialog>



      <Dialog
        // open={true}
        open={successDialogue}
        onClose={handleSDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius:3 }}>


        <CloseIcon onClick={handleOpenQr} sx={{ cursor:'pointer', position:'absolute', right:0, color:'red', fontSize:"1.2rem",  }} />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', margin:0, paddding:0 }} >
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}  >
            <Box component='img' src={eezibLogo} sx={{ width:'6rem'

             }} />
                <Divider variant='middle' sx={{ width:"100%", mt:1.5 }} />
              <Box component='img' src={orderImage} sx={{ width:{lg:"28rem", xs:"18rem" }, padding:0, margin:0, mt:0, backgroundColor:'black' }} />
              <Typography className='orderText' sx={{ fontFamily:'Poppins', fontWeight:700, fontSize:"2.5rem", textTransform:'capitalize', mt:1  }} >Thank You.</Typography>
              <Typography className='errorText' sx={{ fontFamily:'Poppins', fontWeight:500, color:"" ,fontSize:{lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.7rem" }, textTransform:'capitalize'  }} > Order placed successfully, Happy Shopping.</Typography>      
              {/* <Typography  sx={{ fontFamily:'Poppins', fontWeight:400 ,fontSize:"0.75rem", color:"gray", textTransform:'capitalize'  }} >Thanks for shopping and trusting eezib  </Typography>                 */}
          
            </Box>
        </DialogContent>

      </Dialog>

     


      <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={snackRightOpen}
          onClose={handleRightSnackClose}
          autoHideDuration={3000}
        >
             <Alert onClose={handleRightSnackClose} severity='success' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackMessage}</Alert> 
            </Snackbar>  
            

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightEOpen}
                onClose={handleRightSnackEClose}
                autoHideDuration={3000}
              >
             <Alert onClose={handleRightSnackEClose} severity='error' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackEError}</Alert> 
            </Snackbar>



            <Menu
              id="basic-menu"
              anchorEl={anchorEmenu}
              open={ openMenu }
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              
              {
              Array.isArray(currentMenuData) && currentMenuData?.map((data, index) => {          
                return (
                <>
                  <Tooltip key={data.Action} title={data.Action} >
                  <Box component='img' onClick={() => decideRole( data.Action , currentMenuId , data.Api , data.PType ) } src={`${process.env.REACT_APP_UAPI_URL}${data.Img}`  } sx={{ width:"1.4rem", marginLeft:1, cursor:'pointer' }} />
                  </Tooltip>
                </>
                )
                } )
            }
             
            </Menu>


            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openVoucher}
            onClose={handleVoucherClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Eezib Gift Voucher</MenuItem>
            
          </Menu>


    </Box>
    )
}

export default ActivatedCard